import React, { Component, Fragment } from 'react';
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";

const NO_IMG = '/assets/images/No-image.png';
const IMG_PATH = 'http://updates.touchsource.com/Updates/PROP_NUM/Common/Program/';
const IMG_PATH_SM = `${IMG_PATH}sm_`;

class ImgUpload extends Component {
  state = {
    orgSrc: this.props.value,
    src: this.props.value,
    newFileSelectedForUpload: false,
    isPDF: false,
    canPDF: (this.props.field.type === 'img_and_pdf_upload'),

    acceptedFiles: [],
    rejectedFiles: [],
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      let v = this.props.value || '';
      v = v.toLowerCase();

      let name = '';

      let isPDF = false;
      if (~v.indexOf('.pdf')) {
        isPDF = true;

        let s = this.props.value.split('_');
        s.shift();
        name = s.join('_');
      }

      this.setState({
        orgSrc: this.props.value,
        src: this.props.value,
        newFileSelectedForUpload: false,
        isPDF,
        name,
      });
    }

    if (prevProps.clearForm !== this.props.clearForm && this.props.clearForm === true) {
      this.setState({
        orgSrc: '',
        src: '',
        newFileSelectedForUpload: false,
        isPDF: false,
      }, (e) => {
        let { label, field } = this.props;
        let id = `form-field-${field.key}`;
        let elm = document.getElementById(id);
        if (elm) {
          elm.value = '';
        }
      });
    }
  }

  buildSrc(src) {
    let section;
    try {
      section = this.props.form.props.section;
    } catch (err) {
      // console.error(err);
    }

    if (!src) {
      src = NO_IMG;
    } else {
      if (typeof src === 'string') {
        let s = src.toLowerCase();
        if (
          !~s.indexOf('data:image') &&
          (~s.indexOf('.jpg') || ~s.indexOf('jpeg') || ~s.indexOf('.png'))
        ) {
          let propNum = sessionStorage['selected_property_number'];
          if (
            section === 'individuals' ||
            section === 'amenities' ||
            section === 'announcements' ||
            section === 'listData'
          ) {
            src = `${IMG_PATH}${src}`.replace('PROP_NUM', propNum);
          } else {
            src = `${IMG_PATH_SM}${src}`.replace('PROP_NUM', propNum);
          }
        }

        src = `${src}#${(new Date()).getTime()}`;
      }
    }

    return src;
  }

  checkString(str) {
    let reg = /^[a-zA-Z0-9\!\-\_\.\*\'\(\)]*$/g
    if (!reg.test(str)) {
      return false;
    } else {
      return true;
    }
  }

  renderLarge(label, field, id, src, fieldKey) {
    return (
      <div className="form-edit-information">
        <div className="thumb-img">
          <Dropzone
            multiple={false}
            onDrop={(acceptedFiles) => {
              this.setState({
                acceptedFiles,
              });

              acceptedFiles.forEach((file) => {
                const reader = new FileReader()

                reader.onabort = () => {
                  this.setState({
                    src: '',
                    newFileSelectedForUpload: false,
                  });
                  this.props.form.onChange();
                }

                reader.onload = (e) => {
                  if (file.type === 'application/pdf') {
                    this.setState({
                      isPDF: true,
                    });
                  }

                  this.setState({
                    src: e.target.result,
                    newFileSelectedForUpload: true,
                  });
                  this.props.form.onChange();
                }

                reader.readAsDataURL(file);

                this.props.parent.props.parent.setState({ imgUpload: file })
              });
            }}
          >
            {({ getRootProps, getInputProps }) => {
              let isPDF = false;
              if (isPDF) {
                return (
                  <div className="slideshow-pdficon">
                    <i className="fa fa-file-pdf-o" /> <br />
                    {this.state.tmp_fileName}
                  </div>
                )
              }

              return (
                <Fragment>
                  <div className="imgUploadBox" {...getRootProps()}>
                    <img src={src} />
                    <input id={id} name={fieldKey} {...getInputProps()} />
                  </div>

                  {/* 
                    no new file selected for upload 
                    so we store the data in a hidden field as a string
                  */}
                  {
                    !this.state.newFileSelectedForUpload &&
                    <input type="hidden" name={field.key} value={this.state.src || ''} readOnly />
                  }
                </Fragment>
              );

              // <section>
              //   <div {...getRootProps()}>
              //     <input {...getInputProps()} />
              //     <p>Drag 'n' drop some files here, or click to select files</p>
              //   </div>
              // </section>
            }}
          </Dropzone>
        </div>
      </div>
    )
  }

  render() {
    let { label, field } = this.props;
    let id = `form-field-${field.key}`;
    if (this.props.form.props.formHasNoID) {
      id = '';
    }

    let src = this.buildSrc(this.state.src);

    let fieldKey = field.key;
    if (!this.state.newFileSelectedForUpload) {
      fieldKey = '';
    }

    if (field.type === 'img_upload_large') {
      return this.renderLarge(label, field, id, src, fieldKey);
    }

    return (
      <Fragment>
        <label>{label}<div className="imgUpload-message">
          {this.state.canPDF &&
            <div>*Image format: <strong>.JPG</strong> and <strong>.PDF</strong></div>
          }
          {!this.state.canPDF &&
            <div>*Image format: <strong>.JPG</strong> and <strong>.PNG</strong></div>
          }
        </div></label>

        <div className="edit-form-image">
          {!this.state.isPDF &&
            <img src={src} alt="empty" />
          }

          {this.state.isPDF &&
            <div className="pdf-upload">
              <i className="fa fa-file-pdf-o"></i>
              <br />
              <span className="text-ellipsis">{this.state.name}</span>
            </div>
          }
        </div>

        <div className="edit-form-button-group">
          {/* 
            no new file selected for upload 
            so we store the data in a hidden field as a string
          */}
          {!this.state.newFileSelectedForUpload &&
            <input type="hidden" name={field.key} value={this.state.src || ''} readOnly />
          }

          <input type="file" id={id} name={fieldKey} style={{ display: 'none' }}
            onChange={(e) => {
              let orgSrc = this.state.orgSrc;
              let file = e.target.files[0];
              if (!file) {
                // Prevent empty file from being saved
                this.setState({
                  src: orgSrc,
                  newFileSelectedForUpload: false
                }, () => {
                  document.getElementById(id).value = '';
                  this.props.form.onChange();
                });

                return;
              }

              // Check for invalid file names
              if (!this.checkString(file.name)) {
                toast.error("Please use a valid file name. The file name cannot contain: a space, \\, /,:, ?,\",<, , >, or |.", {
                  position: toast.POSITION.TOP_RIGHT
                });

                this.setState({
                  src: orgSrc,
                  newFileSelectedForUpload: false
                }, () => {
                  document.getElementById(id).value = '';
                  this.props.form.onChange();
                });

                return;
              }

              // Allow PNGs for Logos only
              if ((file.type === 'image/jpeg' || file.type === 'image/png' && ~this.props.label.indexOf('Logo')) || (file.type === 'image/jpeg' && !~this.props.label.indexOf('Logo'))) {
                let reader = new FileReader();
                reader.onload = (e) => {
                  this.setState({
                    src: e.target.result,
                    newFileSelectedForUpload: true, // WARN: could be same selected file
                    isPDF: false,
                  });
                  this.props.form.onChange();
                }
                reader.readAsDataURL(file);
              } else if (this.state.canPDF && file.type === 'application/pdf') {
                this.setState({
                  name: file.name,
                  newFileSelectedForUpload: true, // WARN: could be same selected file
                  isPDF: true,
                });

                window.setTimeout(() => {
                  this.props.form.onChange();
                }, 100);
              } else {
                toast.error("Please use the correct file format.", {
                  position: toast.POSITION.TOP_RIGHT
                });

                this.setState({
                  src: orgSrc,
                  newFileSelectedForUpload: false
                }, () => {
                  document.getElementById(id).value = '';
                  this.props.form.onChange();
                });
              }
            }}
          />

          <button className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              document.getElementById(id).click();
            }}
          >
            {(this.state.src) ? 'Replace Image' : 'Upload Image'}
          </button>

          {this.state.src &&
            <button className="btn btn-secondary"
              onClick={(e) => {
                e.preventDefault();

                this.setState({
                  src: '',
                  newFileSelectedForUpload: false,
                  isPDF: false,
                }, () => {
                  document.getElementById(id).value = '';
                  this.props.form.onChange();
                });
              }}
            >Delete Image</button>
          }
        </div>
      </Fragment>
    )
  }
}

export default ImgUpload;