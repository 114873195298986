import React, { Component } from 'react';

export default class ErrorNotFound extends Component {

    render() {
        return (
            <div id="error">
                <h1 className="notFoundTitle"><span>404</span><br/>Oops! It seems the page you are looking for could not be found.</h1>
                <p className="notFoundDesc">
                        It looks like nothing was found at this location. The page could have been removed or the url above could be incorrect.
                </p>
                <a className="btn" href="/dashboard/content/companies">Go Back to Home</a>
            </div>
        );
    }
}