import React from 'react';

class Loader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }
  render() {
    return (
      <div>
        <div className="loader-opacity" />
        <div className="loader_overlay">
          <div className="loader_wrap">

            <div class="loader loader-bars">
              <span></span>	
            </div>

            <div class="custom-loader-message-container">
              <h2>{this.props.loadingTitle}</h2>
              <p>{this.props.loadingMessage}</p>
            </div>
          
          </div>
        </div>
      </div>
    );
  }
}

export default Loader;