const sort = (data, sortKey) => {
  data.sort((a, b) => {
    if (a[sortKey] < b[sortKey]) {
      return -1;
    }

    if (a[sortKey] > b[sortKey]) {
      return 1;
    }

    if (
      typeof a[sortKey] !== 'undefined' &&
      typeof b[sortKey] !== 'undefined'
    ) {
      if (a.key === b.key) {
        console.warn(`${sortKey} should not be the same!`, a, b);
      }
    }
    return 0;
  });

  return data;
}

let propNum = parseInt(sessionStorage['selected_property_number']);

const toggledOn = (d, contentData) => {
  let toggleType = 'any';
  if (d.toggleType) {
    toggleType = d.toggleType;
  }

  if (toggleType === 'all') {
    let tmp = [];
    for (let k of d.toggles) {
      let v = contentData[k.replace('!', '')];
      if (!~k.indexOf('!') && v && v === '1') {
        tmp.push({ key: k, val: 1 });
        continue;
      }
      if (~k.indexOf('!') && v && v === '0') {
        tmp.push({ key: k, val: 1 });
        continue;
      }
      tmp.push({ key: k, val: 0 });
    }

    if (~JSON.stringify(tmp).indexOf('"val":0')) {
      return false;
    }
    return true;
  }

  if (toggleType === 'any') {
    for (let k of d.toggles) {
      let v = contentData[k];
      if (v && v === '1') {
        return true;
      }
    }
  }

  return false;
}

const getSectionData = (section) => {
  if (section === 'slideshow2') {
    section = 'slideshow';
  }

  let data = _sectionFieldData[section];
  if (!data) {
    console.warn('Section Data Missing:', section);
    return allFieldData;
  }

  let allDataTmp = data.concat(allFieldData);
  let allData = [];
  allDataTmp.forEach((d) => {
    allData.push(d);
  });
  return allData;
};

export const getSectionCols = (section, contentData) => {
  if (section === 'slideshow2') {
    section = 'slideshow';
  }

  let data = getSectionData(section).filter((d) => {
    let colOrder = Number(d.colOrder);
    if (typeof d.toggles === 'undefined' && colOrder) {
      return d;
    }

    if (typeof d.toggles !== 'undefined' && colOrder) {
      if (toggledOn(d, contentData)) {
        return d;
      }
    }

    return false;
  });

  // data.push({key: 'ID', defaultLabel: 'ID', col: true, colOrder: 0,});
  data = sort(data, 'colOrder');
  return data;
}

export const getSectionFields = (section, contentData, formName = false) => {
  if (section === 'slideshow2') {
    section = 'slideshow';
  }

  let fields = getSectionData(section).filter((d) => {
    if (typeof d.type === 'undefined') {
      d.type = 'hidden';
    }

    if (typeof d.toggles === 'undefined') {
      return d;
    }

    if (typeof d.toggles !== 'undefined') {
      if (toggledOn(d, contentData)) {
        return d;
      }
      if (d.toggleType === 'all') {
        return false;
      }
    }

    d.type = 'hidden';
    return d;
  });

  if (!formName) {
    fields = sort(fields, 'formOrder');
  }
  fields.sort((a, b) => {
    let aType = a.type;
    let bType = b.type;

    if (aType === 'hidden' && bType !== 'hidden') {
      return 1;
    }
    if (aType !== 'hidden' && bType === 'hidden') {
      return -1;
    }
    return 0;
  });

  // console.log(fields);
  return fields;
}

export const getSectionFieldsByFormName = (formName, section, contentData) => {
  let tmpFields = getSectionFields(section, contentData, true);

  // HACK: don't duplicate object?
  let fields = JSON.parse(JSON.stringify(tmpFields));
  fields.forEach((field) => {
    if (formName !== field.formName) {
      field.type = 'hidden';
      field.formOrder += (tmpFields.length + 1);
    }
  })
  fields = sort(fields, 'formOrder');

  // console.log(fields);
  return fields;
}

export const allFieldData = [
  {
    key: 'ID',
  },
  {
    key: 'CreatedAt',
  },
  {
    key: 'UpdatedAt',
  },
  // {
  //   key: 'DeletedAt',
  // },
];

let _sectionFieldData = {
  companies: [
    {
      key: 'company_name',
      type: 'text',
      required: true,
      labelKey: 'comp_name_label',
      defaultLabel: 'Company Name',
      colOrder: 1,
      formOrder: 1,
    },
    {
      key: 'suite',
      type: 'text',
      required: true,
      labelKey: 'comp_suite_label',
      defaultLabel: 'Suite',
      colOrder: 2,
      formOrder: 2,
    },
    {
      key: 'building',
      type: 'text',
      labelKey: 'comp_bldg_label',
      defaultLabel: 'Building',
      toggles: ['comp_bldg', '!multiple_bldg'],
      toggleType: 'all',
      colOrder: 3,
      formOrder: 4,
    },
    {
      key: 'building',
      type: 'select',
      dataKey: 'buildings',
      labelKey: 'comp_bldg_label',
      defaultLabel: 'Building',
      toggles: ['comp_bldg', 'multiple_bldg'],
      toggleType: 'all',
      colOrder: 3,
      formOrder: 4,
    },
    {
      key: 'floor',
      type: 'text',
      defaultLabel: 'Floor',
      labelKey: 'comp_floor_label',
      toggles: ['comp_floor'],
      colOrder: 4,
      formOrder: 3,
    },
    {
      key: 'phone',
      type: 'text',
      labelKey: 'comp_phone_label',
      defaultLabel: 'Phone',
      toggles: ['comp_phone'],
      colOrder: 5,
      formOrder: 5,
    },

    // company_name
    // suite
    // floor
    // building
    // phone
    {
      key: 'description',
      type: 'textarea',
      labelKey: 'comp_descrip_label',
      defaultLabel: 'Description',
      toggles: ['comp_description'],
      formOrder: 6,
    },
    {
      key: 'logo',
      type: 'img_upload',
      fileKey: 'logo',
      labelKey: 'comp_logo_label',
      defaultLabel: 'Logo',
      toggles: ['comp_logo'],
      formOrder: 7,
    },
    {
      key: 'comp_image',
      type: 'img_and_pdf_upload',
      fileKey: 'comp_image',
      labelKey: 'comp_image_label',
      defaultLabel: 'Company Image',
      toggles: ['comp_image'],
      formOrder: 8,
    },

    {
      key: 'company_id',
    },
    {
      key: 'prop_num',
    },
    {
      key: 'type',
      value: 'Comp',
    },
    {
      key: 'map_name',
    },
    {
      key: 'directions',
    },
    {
      key: 'xy_coord',
    },
    {
      key: 'last_mod_date',
    },
  ],

  individuals: [
    {
      key: 'individual_name',
      type: 'text',
      required: true,
      labelKey: 'ind_name_label',
      defaultLabel: 'Individual Name',
      colOrder: 1,
      formOrder: 1,
    },
    {
      key: 'company_name',
      type: 'select',
      dataKey: 'companies',
      idKey: 'company_id',
      required: true,
      labelKey: 'ind_comp_label',
      defaultLabel: 'Company Name',
      colOrder: 2,
      formOrder: 3,
    },
    {
      key: 'suite',
      type: 'text',
      required: true,
      labelKey: 'ind_suite_label',
      defaultLabel: 'Suite',
      colOrder: 3,
      formOrder: 4,
    },
    {
      key: 'i_building',
      type: 'text',
      labelKey: 'indi_building_label',
      defaultLabel: 'Building',
      toggles: ['indi_building', '!multiple_bldg'],
      toggleType: 'all',
      colOrder: 4,
      formOrder: 5,
    },
    {
      key: 'i_building',
      type: 'select',
      dataKey: 'buildings',
      labelKey: 'indi_building_label',
      defaultLabel: 'Building',
      toggles: ['indi_building', 'multiple_bldg'],
      toggleType: 'all',
      colOrder: 4,
      formOrder: 5,
    },
    {
      key: 'floor',
      type: 'text',
      labelKey: 'ind_floor_label',
      defaultLabel: 'Floor',
      toggles: ['ind_floor'],
      colOrder: 5,
      formOrder: 6,
    },
    {
      key: 'ranking',
      type: 'number',
      defaultLabel: 'Rank',
      helpText: '(Use to sort other than alphabetically. The highest number shows at the TOP of the list.)',
      toggles: ['ind_ranking'],
      colOrder: 6,
      formOrder: 11,
    },
    {
      key: 'ind_custom_1',
      type: 'text',
      labelKey: 'ind_custom_1_label',
      defaultLabel: 'Custom 1',
      toggles: ['ind_custom_1'],
      colOrder: 7,
      formOrder: 8,
    },
    {
      key: 'ind_custom_2',
      type: 'text',
      labelKey: 'ind_custom_2_label',
      defaultLabel: 'Custom 2',
      toggles: ['ind_custom_2'],
      colOrder: 8,
      formOrder: 9,
    },
    {
      key: 'phone',
      type: 'text',
      labelKey: 'ind_phone_label',
      defaultLabel: 'Phone',
      toggles: ['ind_phone'],
      colOrder: 9,
      formOrder: 7,
    },

    // individual_name
    {
      key: 'title',
      type: 'text',
      labelKey: 'ind_title_label',
      defaultLabel: 'Title',
      formOrder: 2,
    },
    // company_name
    // suite
    // i_building
    // floor
    // phone
    // ind_custom_1
    // ind_custom_2
    {
      key: 'ind_memo',
      type: 'textarea',
      labelKey: 'ind_memo_label',
      defaultLabel: 'Memo',
      toggles: ['ind_memo'],
      formOrder: 10,
    },
    // ranking
    {
      key: 'ind_image',
      type: 'img_and_pdf_upload',
      fileKey: 'ind_image',
      labelKey: 'ind_image_label',
      defaultLabel: 'Image',
      toggles: ['ind_image'],
      formOrder: 12,
    },

    {
      key: 'ind_id',
    },
    {
      key: 'company_id',
    },
    {
      key: 'prop_num',
    },
    {
      key: 'type',
      value: 'Ind',
    },
    {
      key: 'create_date',
    },
  ],

  amenities: [
    {
      key: 'amenity_name',
      type: 'text',
      required: true,
      labelKey: 'amen_name_label',
      defaultLabel: 'Amenity Name',
      colOrder: 1,
      formOrder: 1,
    },
    {
      key: 'location',
      type: 'text',
      required: true,
      labelKey: 'amen_location_label',
      defaultLabel: 'Location',
      colOrder: 2,
      formOrder: 5,
    },
    {
      key: 'phone',
      type: 'text',
      labelKey: 'amen_phone_label',
      defaultLabel: 'Phone',
      colOrder: 3,
      formOrder: 11,
    },

    {
      key: 'amenity_type',
      type: 'text',
      toggles: ['amen_type'],
      defaultLabel: 'Category',
      formOrder: 2,
    },
    {
      key: 'suite',
      type: 'text',
      defaultLabel: 'Suite',
      toggles: ['amen_suite'],
      formOrder: 3,
    },
    {
      key: 'building',
      type: 'text',
      defaultLabel: 'Building',
      toggles: ['amen_bldg', '!multiple_bldg'],
      toggleType: 'all',
      formOrder: 4,
    },
    {
      key: 'building',
      type: 'select',
      dataKey: 'buildings',
      defaultLabel: 'Building',
      toggles: ['amen_bldg', 'multiple_bldg'],
      toggleType: 'all',
      formOrder: 4,
    },
    // location
    {
      key: 'on_site',
      type: 'radio',
      defaultLabel: 'On Site?',
      toggles: ['amen_onsite'],
      formOrder: 6,
    },
    {
      key: 'address',
      type: 'text',
      defaultLabel: 'Address',
      toggles: ['amen_address'],
      formOrder: 7,
    },
    {
      key: 'city',
      type: 'text',
      defaultLabel: 'City',
      toggles: ['amen_city'],
      formOrder: 8,
    },
    {
      key: 'state',
      type: 'select',
      dataKey: 'states',
      defaultLabel: 'State',
      toggles: ['amen_state'],
      formOrder: 9,
    },
    {
      key: 'zip',
      type: 'text',
      defaultLabel: 'Zip',
      toggles: ['amen_zip'],
      formOrder: 10,
    },
    // phone
    {
      key: 'amen_qrurl',
      type: 'text',
      defaultLabel: 'QR Code URL',
      toggles: ['amen_qr_code'],
      formOrder: 12,
    },
    {
      key: 'amen_custom_1',
      type: 'text',
      labelKey: 'amen_custom_1_label',
      defaultLabel: 'Custom 1',
      toggles: ['amen_custom_1'],
      formOrder: 13,
    },
    {
      key: 'amen_custom_2',
      type: 'text',
      labelKey: 'amen_custom_2_label',
      defaultLabel: 'Custom 2',
      toggles: ['amen_custom_2'],
      formOrder: 14,
    },
    {
      key: 'description',
      type: 'textarea',
      labelKey: 'amen_description_label',
      defaultLabel: 'Description',
      formOrder: 15,
    },
    {
      key: 'photo_file',
      type: 'img_and_pdf_upload',
      fileKey: 'photo_file',
      labelKey: 'amen_photo_file_label',
      defaultLabel: 'Image',
      toggles: ['amen_photo'],
      formOrder: 16,
    },

    {
      key: 'amenity_id',
    },
    {
      key: 'prop_num',
    },
    {
      key: 'create_date',
    },
    {
      key: 'web_link',
    },
    {
      key: 'amen_qr_link',
    },
    {
      key: 'amen_qr_desc',
    },
  ],

  spaceAvailable: [
    {
      key: 'suite',
      type: 'text',
      required: true,
      labelKey: 'space_suite_label',
      defaultLabel: 'Suite',
      colOrder: 1,
      formOrder: 1,
    },
    {
      key: 'space_size',
      type: 'text',
      required: true,
      labelKey: 'space_size_label',
      defaultLabel: 'Space Size',
      colOrder: 2,
      formOrder: 4,
    },
    {
      key: 'building',
      type: 'text',
      defaultLabel: 'Building',
      toggles: ['space_bldg', '!multiple_bldg'],
      toggleType: 'all',
      colOrder: 3,
      formOrder: 3,
    },
    {
      key: 'building',
      type: 'select',
      dataKey: 'buildings',
      defaultLabel: 'Building',
      toggles: ['space_bldg', 'multiple_bldg'],
      toggleType: 'all',
      colOrder: 3,
      formOrder: 3,
    },
    {
      key: 'availability',
      type: 'text',
      labelKey: 'space_avail_label',
      defaultLabel: 'Availability',
      colOrder: 4,
      formOrder: 6,
    },

    // suite
    {
      key: 'floor',
      type: 'text',
      defaultLabel: 'Floor',
      toggles: ['space_floor'],
      formOrder: 2,
    },
    // building
    // space_size
    {
      key: 'cost',
      type: 'text',
      labelKey: 'space_cost_label',
      defaultLabel: 'Cost',
      formOrder: 5,
    },
    // availability
    {
      key: 'description',
      type: 'textarea',
      defaultLabel: 'Description',
      formOrder: 7,
    },
    {
      key: 'floorplan',
      type: 'pdf_upload',
      fileKey: 'pdf_file_name',
      defaultLabel: 'PDF Floorplan',
      toggles: ['space_floorplan'],
      formOrder: 8,
    },

    {
      key: 'space_id',
    },
    {
      key: 'prop_num',
    },
    {
      key: 'create_date',
    },
  ],

  announcements: [
    {
      key: 'name',
      type: 'text',
      required: true,
      labelKey: 'announce_label',
      defaultLabel: 'Name',
      colOrder: 1,
      formOrder: 1,
    },
    {
      key: 'location',
      type: 'text',
      required: true,
      defaultLabel: 'Location',
      colOrder: 2,
      formOrder: 2,
    },
    {
      key: 'start_date',
      type: 'date',
      required: true,
      defaultLabel: 'Start Date',
      colOrder: 3,
      formOrder: 8,
    },
    {
      key: 'end_date',
      type: 'date',
      defaultLabel: 'End Date',
      colOrder: 4,
      formOrder: 9,
      futureTime: true
    },
    {
      key: 'start_time',
      type: 'time',
      defaultLabel: 'Start Time',
      colOrder: 5,
      formOrder: 10,
    },
    {
      key: 'end_time',
      type: 'time',
      defaultLabel: 'End Time',
      colOrder: 6,
      formOrder: 11,
    },

    // name
    // location
    {
      key: 'location_2',
      type: 'text',
      defaultLabel: 'Location 2',
      formOrder: 3,
    },
    {
      key: 'description',
      type: 'text',
      defaultLabel: 'Description',
      formOrder: 4,
    },
    {
      key: 'building',
      type: 'text',
      defaultLabel: 'Building',
      toggles: ['announce_bldg', '!multiple_bldg'],
      toggleType: 'all',
      formOrder: 5,
    },
    {
      key: 'building',
      type: 'select',
      dataKey: 'buildings',
      defaultLabel: 'Building',
      toggles: ['announce_bldg', 'multiple_bldg'],
      toggleType: 'all',
      formOrder: 5,
    },
    {
      key: 'suite',
      type: 'text',
      defaultLabel: 'Suite',
      toggles: ['announce_suite'],
      formOrder: 6,
    },
    {
      key: 'display_start',
      type: 'date',
      defaultLabel: 'Display Start',
      formOrder: 7,
    },
    // start_date
    // end_date
    // start_time
    // end_time
    {
      key: 'announce_image_name',
      type: 'img_and_pdf_upload',
      fileKey: 'announce_image_name',
      labelKey: 'announce_image_label',
      defaultLabel: 'Image',
      toggles: ['announce_image'],
      formOrder: 12,
    },
    {
      key: 'auto_delete',
      type: 'radio',
      defaultLabel: 'Auto Delete',
      formOrder: 13,
    },


    {
      key: 'access_id',
    },
    {
      key: 'prop_num',
    },
    {
      key: 'type',
    },
    {
      key: 'create_date',
    },
  ],

  listData: [
    {
      key: 'text_1',
      type: 'text',
      required: true,
      labelKey: 'l_12_text_label_1',
      defaultLabel: 'Text 1',
      colOrder: 1,
      formOrder: 1,
    },
    {
      key: 'text_2',
      type: 'text',
      labelKey: 'l_12_text_label_2',
      defaultLabel: 'Text 2',
      toggles: ['list_12_text_2'],
      colOrder: 2,
      formOrder: 2,
    },
    {
      key: 'text_3',
      type: 'text',
      labelKey: 'l_12_text_label_3',
      defaultLabel: 'Text 3',
      toggles: ['list_12_text_3'],
      colOrder: 3,
      formOrder: 3,
    },
    {
      key: 'text_4',
      type: 'text',
      labelKey: 'l_12_text_label_4',
      defaultLabel: 'Text 4',
      toggles: ['list_12_text_4'],
      colOrder: 4,
      formOrder: 4,
    },
    {
      key: 'text_5',
      type: 'text',
      labelKey: 'l_12_text_label_5',
      defaultLabel: 'Text 5',
      toggles: ['list_12_text_5'],
      colOrder: 5,
      formOrder: 5,
    },
    {
      key: 'text_6',
      type: 'text',
      labelKey: 'l_12_text_label_6',
      defaultLabel: 'Text 6',
      toggles: ['list_12_text_6'],
      colOrder: 6,
      formOrder: 6,
    },
    {
      key: 'text_7',
      type: 'text',
      labelKey: 'l_12_text_label_7',
      defaultLabel: 'Text 7',
      toggles: ['list_12_text_7'],
      colOrder: 7,
      formOrder: 7,
    },
    {
      key: 'text_8',
      type: 'text',
      labelKey: 'l_12_text_label_8',
      defaultLabel: 'Text 8',
      toggles: ['list_12_text_8'],
      colOrder: 8,
      formOrder: 8,
    },
    {
      key: 'date_1',
      type: 'date',
      labelKey: 'l_12_date_label_1',
      defaultLabel: 'Date 1',
      toggles: ['list_12_date_1'],
      colOrder: 9,
      formOrder: 9,
    },
    {
      key: 'date_2',
      type: 'date',
      labelKey: 'l_12_date_label_2',
      defaultLabel: 'Date 2',
      toggles: ['list_12_date_2'],
      colOrder: 10,
      formOrder: 10,
    },
    {
      key: 'number_1',
      type: 'number',
      labelKey: 'l_12_num_label_1',
      defaultLabel: 'Number 1',
      toggles: ['list_12_number_1'],
      colOrder: 11,
      formOrder: 11,
    },
    {
      key: 'number_2',
      type: 'number',
      labelKey: 'l_12_num_label_2',
      defaultLabel: 'Number 2',
      toggles: ['list_12_number_2'],
      colOrder: 12,
      formOrder: 12,
    },
    {
      key: 'memo_1',
      type: 'textarea',
      labelKey: 'l_12_memo_label_1',
      defaultLabel: 'Memo 1',
      toggles: ['list_12_memo_1'],
      colOrder: 13,
      formOrder: 15,
    },

    // text_1
    // text_2
    // text_3
    // text_4
    // text_5
    // text_6
    // text_7
    // text_8
    // date_1
    // date_2
    // number_1
    // number_2
    {
      key: 'boolean_1',
      type: 'radio',
      labelKey: 'l_12_bool_label_1',
      defaultLabel: 'Yes/No 1',
      toggles: ['list_12_boolean_1'],
      formOrder: 13,
    },
    {
      key: 'boolean_2',
      type: 'radio',
      labelKey: 'l_12_bool_label_2',
      defaultLabel: 'Yes/No 2',
      toggles: ['list_12_boolean_2'],
      formOrder: 14,
    },
    // memo_1
    {
      key: 'image_name',
      type: 'img_and_pdf_upload',
      fileKey: 'image_name',
      labelKey: 'l_12_image_label_1',
      defaultLabel: 'Image',
      toggles: ['list_12_image_name'],
      formOrder: 16
    },

    {
      key: 'access_id',
    },
    {
      key: 'prop_num',
    },
  ],

  pdfUpload: [
    {
      key: 'pdf_name',
      type: 'pdf_upload',
      fileKey: 'pdf_name',
      labelKey: 'pdf_upload_label',
      defaultLabel: 'PDF Information',
      toggles: ['pdf_upload'],
      formOrder: 1,
    },
    {
      key: 'pdf_2_name',
      type: 'pdf_upload',
      fileKey: 'pdf_2_name',
      labelKey: 'pdf_2_label',
      defaultLabel: 'PDF Info 2',
      toggles: ['pdf_upload_2'],
      formOrder: 2,
    },
    {
      key: 'pdf_3_name',
      type: 'pdf_upload',
      fileKey: 'pdf_3_name',
      labelKey: 'pdf_3_label',
      defaultLabel: 'PDF Info 3',
      toggles: ['pdf_upload_3'],
      formOrder: 3,
    },
    {
      key: 'pdf_4_name',
      type: 'pdf_upload',
      fileKey: 'pdf_4_name',
      labelKey: 'pdf_4_label',
      defaultLabel: 'PDF Info 4',
      toggles: ['pdf_upload_4'],
      formOrder: 4,
    },

    {
      key: 'prop_num',
    },
    {
      key: 'property_name',
    },
    {
      key: 'address',
    },
    {
      key: 'building_photo',
    },
    {
      key: 'building_logo',
    },
    {
      key: 'management_contact',
    },
    {
      key: 'management_company',
    },
    {
      key: 'management_location',
    },
    {
      key: 'management_phone',
    },
    {
      key: 'management_logo',
    },
    {
      key: 'emergency_contact',
    },
    {
      key: 'leasing_contact',
    },
    {
      key: 'leasing_company',
    },
    {
      key: 'leasing_location',
    },
    {
      key: 'leasing_phone',
    },
    {
      key: 'leasing_line_5',
    },
    {
      key: 'leasing_logo',
    },
    {
      key: 'description',
    },
    {
      key: 'scroll_msg',
    },
    {
      key: 'city',
    },
    {
      key: 'state',
    },
    {
      key: 'zip',
    },
    {
      key: 'latitude',
    },
    {
      key: 'longitude',
    },
  ],

  buildingInfo: [
    {
      key: 'property_name',
      type: 'text',
      required: true,
      defaultLabel: 'Property Name'
    },
    {
      key: 'building_logo',
      type: 'img_upload',
      fileKey: 'building_logo',
      defaultLabel: 'Main Screen Logo',
      toggles: ['main_logo_display'],
    },
    {
      key: 'management_contact',
      type: 'text',
      defaultLabel: 'Management Info',
      toggles: ['property'],
    },
    {
      key: 'management_company',
      type: 'text',
      defaultLabel: 'Management Info',
      toggles: ['property'],
    },
    {
      key: 'management_location',
      type: 'text',
      defaultLabel: 'Management Info',
      toggles: ['property'],
    },
    {
      key: 'management_phone',
      type: 'text',
      defaultLabel: 'Management Info',
      toggles: ['property'],
    },
    {
      key: 'emergency_contact',
      type: 'text',
      defaultLabel: 'Management Info',
      toggles: ['property'],
    },
    {
      key: 'management_logo',
      type: 'img_upload',
      fileKey: 'management_logo',
      defaultLabel: 'Management Logo',
      toggles: ['prop_mgmt_logo'],
    },
    {
      key: 'leasing_contact',
      type: 'text',
      defaultLabel: 'Leasing Info',
      toggles: ['property'],
    },
    {
      key: 'leasing_company',
      type: 'text',
      defaultLabel: 'Leasing Info',
      toggles: ['property'],
    },
    {
      key: 'leasing_location',
      type: 'text',
      defaultLabel: 'Leasing Info',
      toggles: ['property'],
    },
    {
      key: 'leasing_phone',
      type: 'text',
      defaultLabel: 'Leasing Info',
      toggles: ['property'],
    },
    {
      key: 'leasing_line_5',
      type: 'text',
      defaultLabel: 'Leasing Info',
      toggles: ['property'],
    },
    {
      key: 'leasing_logo',
      type: 'img_upload',
      fileKey: 'leasing_logo',
      defaultLabel: 'Leasing Logo',
      toggles: ['prop_lsg_logo'],
    },
    {
      key: 'description',
      type: 'textarea',
      defaultLabel: 'Other Mgmt Info',
      toggles: ['prop_descrip'],
    },
    {
      key: 'scroll_msg',
      type: 'textarea',
      defaultLabel: 'Scrolling Message',
      toggles: ['prop_scroll_msg'],
    },

    {
      key: 'prop_num',
    },
    {
      key: 'address',
    },
    {
      key: 'building_photo',
    },
    {
      key: 'pdf_name',
    },
    {
      key: 'pdf_2_name',
    },
    {
      key: 'pdf_3_name',
    },
    {
      key: 'pdf_4_name',
    },
    {
      key: 'city',
    },
    {
      key: 'state',
    },
    {
      key: 'zip',
    },
    {
      key: 'latitude',
    },
    {
      key: 'longitude',
    }
  ],

  slideshow: [
    {
      key: 'file_name',
      type: 'img_upload_large',
      fileKey: 'file_name',
      defaultLabel: 'Img Upload',
      formOrder: 1,
      colOrder: 1,
    },
    {
      key: 'reference_name',
      type: 'text',
      required: true,
      defaultLabel: 'Description',
      formOrder: 2,
    },
    {
      key: 'position__',
      type: 'number',
      defaultLabel: 'Position',
      formOrder: 3,
    },
    {
      key: 'start_date',
      type: 'date',
      defaultLabel: 'Start Date',
      formOrder: 4,
    },
    {
      key: 'expire_date',
      type: 'date',
      defaultLabel: 'End Date',
      formOrder: 5,
      futureTime: true
    },
    {
      key: 'duration',
      type: 'number',
      defaultLabel: 'Duration (sec)',
      formOrder: 6,
    },

    {
      key: 'image_id',
    },
    {
      key: 'prop_num',
    },
    {
      key: 'transition',
    },
    {
      key: 'show__',
      value: '1',
    },
    {
      key: 'ss_type',
    },
  ],

  accountFields: [
    {
      key: 'updating_contact',
      type: 'text',
      required: true,
      defaultLabel: 'Contact Name',
      formName: 'account::management_contact_info',
      formOrder: 1,
    },
    {
      key: 'updating_email',
      type: 'text',
      required: true,
      defaultLabel: 'Contact Email',
      formName: 'account::management_contact_info',
      formOrder: 2,
    },
    {
      key: 'mgmt_contact',
      type: 'text',
      required: true,
      defaultLabel: 'Mgmt Contact Name',
      formName: 'account::management_contact_info',
      formOrder: 3,
    },
    {
      key: 'mgmt_email',
      type: 'text',
      defaultLabel: 'Mgmt Contact Email',
      formName: 'account::management_contact_info',
      formOrder: 4,
    },
    {
      key: 'mgmt_company',
      type: 'text',
      required: true,
      defaultLabel: 'Mgmt Company',
      formName: 'account::management_contact_info',
      formOrder: 5,
    },
    {
      key: 'mgmt_address_1',
      type: 'text',
      required: true,
      defaultLabel: 'Address 1',
      formName: 'account::management_contact_info',
      formOrder: 6,
    },
    {
      key: 'mgmt_address_2',
      type: 'text',
      defaultLabel: 'Address 2',
      formName: 'account::management_contact_info',
      formOrder: 7,
    },
    {
      key: 'mgmt_city',
      type: 'text',
      required: true,
      defaultLabel: 'City',
      formName: 'account::management_contact_info',
      formOrder: 8,
    },
    {
      key: 'mgmt_state',
      type: 'select',
      dataKey: 'states',
      required: true,
      defaultLabel: 'State/ Province',
      formName: 'account::management_contact_info',
      formOrder: 9,
    },
    {
      key: 'mgmt_postal',
      type: 'text',
      defaultLabel: 'Zip / Postal Code',
      formName: 'account::management_contact_info',
      formOrder: 10,
    },
    {
      key: 'mgmt_phone',
      type: 'text',
      defaultLabel: 'Phone',
      formName: 'account::management_contact_info',
      formOrder: 11,
    },
    {
      key: 'mgmt_fax',
      type: 'text',
      defaultLabel: 'Fax',
      formName: 'account::management_contact_info',
      formOrder: 12,
    },

    {
      key: 'billing_contact',
      type: 'text',
      required: true,
      defaultLabel: 'Contact Name',
      formName: 'account::edit_billing',
      formOrder: 1,
    },
    {
      key: 'billing_email',
      type: 'text',
      defaultLabel: 'Contact Email',
      formName: 'account::edit_billing',
      formOrder: 2,
    },
    {
      key: 'billing_company',
      type: 'text',
      required: true,
      defaultLabel: 'Company Name',
      formName: 'account::edit_billing',
      formOrder: 3,
    },
    {
      key: 'billing_address_1',
      type: 'text',
      required: true,
      defaultLabel: 'Address 1',
      formName: 'account::edit_billing',
      formOrder: 4,
    },
    {
      key: 'billing_address_2',
      type: 'text',
      defaultLabel: 'Address 2',
      formName: 'account::edit_billing',
      formOrder: 5,
    },
    {
      key: 'billing_city',
      type: 'text',
      required: true,
      defaultLabel: 'City',
      formName: 'account::edit_billing',
      formOrder: 6,
    },
    {
      key: 'billing_state',
      type: 'select',
      dataKey: 'states',
      required: true,
      defaultLabel: 'State / Provience',
      formName: 'account::edit_billing',
      formOrder: 7,
    },
    {
      key: 'billing_postal',
      type: 'text',
      required: true,
      defaultLabel: 'Zip / Postal Code',
      formName: 'account::edit_billing',
      formOrder: 8,
    },
    {
      key: 'billing_phone',
      type: 'text',
      defaultLabel: 'Phone',
      formName: 'account::edit_billing',
      formOrder: 9,
    },
    {
      key: 'billing_fax',
      type: 'text',
      defaultLabel: 'Fax',
      formName: 'account::edit_billing',
      formOrder: 10,
    },

    {
      key: 'company_id',
    },
    {
      key: 'prop_num',
    },
    {
      key: 'archive',
    },
    {
      key: 'cancel_date',
    },
    {
      key: 'cancel_notes',
    },
    {
      key: 'cancel_reason',
    },
    {
      key: 'dsi_notes',
    },
    {
      key: 'first_group',
    },
    {
      key: 'ftp_upload',
    },
    {
      key: 'grid_edit',
    },
    {
      key: 'last_mod_date',
    },
    {
      key: 'last_update',
    },
    {
      key: 'online_expires',
    },
    {
      key: 'online_fee',
    },
    {
      key: 'other_fee',
    },
    {
      key: 'other_fee_desc',
    },
    {
      key: 'pin',
    },
    {
      key: 'regus',
    },
    {
      key: 'reseller',
    },
    {
      key: 'second_group',
    },
    {
      key: 'spec_instructions',
    },
    {
      key: 'static',
    },
    {
      key: 'sub_accounts',
    },
    {
      key: 'subscription_notes',
    },
    {
      key: 'subscription_num',
    },
    {
      key: 'subscription_trial',
    },
    {
      key: 'subscription_type',
    },
    {
      key: 'third_group',
    },
    {
      key: 'tp_bill',
    },
    {
      key: 'tp_bill_amount',
    },
    {
      key: 'tp_bill_email',
    },
    {
      key: 'tp_bill_name',
    },
    {
      key: 'tp_bill_notes',
    },
    {
      key: 'tp_bill_phone',
    },
    {
      key: 'update_type',
    },
    {
      key: 'user_name',
    },
    {
      key: 'version',
    },
    {
      key: 'warranty',
    },
    {
      key: 'warranty_desc',
    },
    {
      key: 'warranty_expires',
    },
    {
      key: 'warranty_fee',
    },
    {
      key: 'web_news_expires',
    },
    {
      key: 'web_news_fee',
    },
  ],

  requestAChange: [
    {
      key: 'name',
      type: 'text',
      required: true,
      defaultLabel: 'Name',
      formOrder: 1,
    },
    {
      key: 'email',
      type: 'text',
      required: true,
      defaultLabel: 'Email',
      formOrder: 2,
    },
    {
      key: 'phone',
      type: 'text',
      required: true,
      defaultLabel: 'Phone',
      formOrder: 3,
    },
    {
      key: 'request',
      type: 'textarea',
      required: true,
      defaultLabel: 'Request',
      formOrder: 4,
    },

    {
      key: 'prop_num',
    },
  ]
};

let l = _sectionFieldData.accountFields.length;

_sectionFieldData.managementContactInfo = [];
for (let i = 0; i < l; i++) {
  let sectionField = Object.assign({}, _sectionFieldData.accountFields[i]);
  if (sectionField.formName !== 'account::management_contact_info') {
    sectionField.required = false;
  }

  _sectionFieldData.managementContactInfo.push(sectionField);
}

_sectionFieldData.editBilling = [];
for (let i = 0; i < l; i++) {
  let sectionField = Object.assign({}, _sectionFieldData.accountFields[i]);
  if (sectionField.formName !== 'account::edit_billing') {
    sectionField.required = false;
  }

  _sectionFieldData.editBilling.push(sectionField);
}

let renewRequiredFieldNames = [
  'billing_address_1',
  'billing_city',
  'billing_company',
  'billing_contact',
  'billing_postal',
  'billing_state',
];

_sectionFieldData.renew = [];
for (let i = 0; i < l; i++) {
  let sectionField = Object.assign({}, _sectionFieldData.accountFields[i]);
  if (!~renewRequiredFieldNames.indexOf(sectionField.key)) {
    sectionField.required = false;
  }

  _sectionFieldData.renew.push(sectionField);
}

export const sectionFieldData = _sectionFieldData;