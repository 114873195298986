export const getNavData = (contentData = {}) => {
  return navData.map((data) => {
    // no toggle(s), always on
    if (typeof data.toggles === 'undefined') {
      return data;
    }

    let enabled = false;
    for (let k of data.toggles) {
      let v = contentData[k];
      if (v && v === '1') {
        enabled = true;
        break;
      }
    }

    if (!enabled) {
      return false;
    }

    return data;
  });
}

export const getSectionNavData = (section) => {
  for (let data of navData) {
    if (section === data.route) {
      return data;
    }
  }
}

export const getSectionAjaxRoute = (section) => {
  if (section === 'buildings') {
    return 'updateBuildings';
  }

  return getSectionNavData(section).ajaxRoute;
}

export const navData = [
  {
    toggles: ['companies'],
    labelKey: 'comp_name_label',
    defaultLabel: 'Companies',
    id: 'companiesListingsClick',
    route: 'companies',
    ajaxRoute: 'updateCompanies',
  },
  {
    toggles: ['individuals'],
    labelKey: 'ind_name_label',
    defaultLabel: 'Individuals',
    id: 'individualsClick',
    route: 'individuals',
    ajaxRoute: 'updateIndividuals',
  },
  {
    labelKey: 'prop_but_label',
    defaultLabel: 'Building Info',
    id: 'buildingInfoClick',
    route: 'buildingInfo',
    ajaxRoute: 'updateProperty',
  },
  {
    toggles: ['amenities'],
    labelKey: 'amen_name_label',
    defaultLabel: 'Amenities',
    id: 'amentitiesClick',
    route: 'amenities',
    ajaxRoute: 'updateAmenities',
  },
  {
    toggles: ['space'],
    labelKey: 'space_suite_label',
    defaultLabel: 'Space Available',
    id: 'spaceListingClick',
    route: 'spaceAvailable',
    ajaxRoute: 'updateSpace',
  },
  {
    toggles: ['pdf_upload', 'pdf_upload_2', 'pdf_upload_3', 'pdf_upload_4'],
    labelKey: '',
    defaultLabel: 'PDF Upload',
    id: 'pdfUploadClick',
    route: 'pdfUpload',
    ajaxRoute: 'updateProperty',
  },
  {
    toggles: ['announcements'],
    labelKey: 'announce_label',
    defaultLabel: 'Announcements',
    id: 'announcementsClick',
    route: 'announcements',
    ajaxRoute: 'updateAnnouncements',
  },
  {
    toggles: ['attract_images'],
    labelKey: 'ss_1_label',
    defaultLabel: 'Slideshow',
    id: 'slideshow1Click',
    route: 'slideshow',
    ajaxRoute: 'updateImages',
  },
  {
    toggles: ['slide_show_2'],
    labelKey: 'ss_2_label',
    defaultLabel: 'Slideshow 2',
    id: 'slideshow2Click',
    route: 'slideshow2',
    ajaxRoute: 'updateImages',
  },
  {
    toggles: ['list_12'],
    labelKey: 'l_12_text_label_1',
    defaultLabel: 'Alt List',
    id: 'list12Click',
    route: 'listData',
    ajaxRoute: 'updateList12',
  },
];