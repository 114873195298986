import React, { Component, Fragment } from 'react';

import kali from 'kali';

import Header from '../component/Header';

const BRONCO_PREFIX = process.env.REACT_APP_BRONCO_PREFIX;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: this.props.mode || 'LOGIN',
      authCode: this.props.authCode || '',
      email: '',
      password: '',
      auth2FACode: '',
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.mode !== this.props.mode) {
      this.setState({
        mode: this.props.mode,
      });
    }
  }

  renderLoginSidebar() {
    return (
      <div className="login-sidebar">
        <div className="login-sidebar-content">
          <h1>Got Content?</h1>
          <p>Amenity Wars have heated up competition—making it hard to get and keep the best tenants. Win hearts, minds; land the best leases in town with an exciting lobby experience they’ll talk about.</p>
          <h2>Find out what you're missing today!</h2>
          <p>
            <ul>
              <li>Sports, News, Weather, Amenities & Traffic</li>
              <li>Entertainment, Health News, National Geographic Photos</li>
              <li>Community Calendars, Billboard News, Inspirational Quotes & More</li>
            </ul>
          </p>
          <a className="btn" target="_blank" href="http://go.pardot.com/l/766713/2019-08-09/2jfg">Explore Touchsource Channels</a>
        </div>
        <div className="login-sidebar-footer">
          <a target="_blank" href="http://go.pardot.com/l/766713/2019-08-09/2jfg">
            <img src="/assets/images/touchsource-nfl-scores.png" alt="Check the Latest NFL scores with Touchsource Channels" />
          </a>
        </div>
        <div className="login-sidebar-content-mobile">
          <a href="http://go.pardot.com/l/766713/2019-08-09/2jfg">Explore Touchsource Channels</a>
        </div>
      </div>
    )
  }

  render() {

    // Two-factor

    let twoFactorSetup = false;

    if (sessionStorage['2fa_img']) {
      twoFactorSetup = true;
      console.log("There is '2fa_img' in session storage ")
    } else {
      console.log('There isnt');
    }

    // Verification code input masking

    let characters = [];

    function renderCode(array) {
      console.log(array);
      if (document.getElementById('verificationCode')) {
        document.getElementById("box1").textContent = array[0];
        document.getElementById("box2").textContent = array[1];
        document.getElementById("box3").textContent = array[2];
        document.getElementById("box4").textContent = array[3];
        document.getElementById("box5").textContent = array[4];
        document.getElementById("box6").textContent = array[5];
      }
    }

    if (this.state.mode === 'LOGIN') {

      document.onkeydown = function (e) {
        if (document.getElementById('login-button')) {
          if (e.keyCode === 13) {
            document.getElementById("login-button").click();
          }
        }
      }

    }

    if (this.state.mode === '2FA') {
      document.onkeydown = function (e) {
        console.log(characters.length);
        if (document.getElementById('verificationCode')) {
          document.getElementById("verificationCode").focus();
        }
        if (e.keyCode >= 48 && e.keyCode <= 57 && characters.length <= 5) {
          characters[characters.length] = e.key;
        } else if (e.keyCode == 8) {
          characters.pop();
        }
        renderCode(characters);
        if (characters.length > 5) {
          console.log(characters.join(''));
          if (document.getElementById('verificationCode')) {
            document.getElementById('verifyButton').classList.add('active');
          }
        }
        if (characters.length < 6) {
          if (document.getElementById('verificationCode')) {
            document.getElementById('verifyButton').classList.remove('active');
          }
        }
      }
    }

    return (
      <Fragment>
        <Header
          app={this.props.app}
        />

        <form>
          <div className="login-container">

            {/* LOGIN */}

            {this.state.mode === 'LOGIN' &&


              <div className="login-mode-container">
                <div className="login-mode">

                  <div className="login-card">
                    <img src="/assets/images/login-logo.png" alt="logo" />

                    <div className="login-form">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      <input autoComplete="off" type="text" id="email" placeholder="Email or Username" defaultValue=""
                        onChange={(e) => {
                          if (e.target.value && this.state.error) {
                            return this.setState({
                              error: false,
                            })
                          };
                        }}
                      />
                    </div>

                    <div className="login-form">
                      <i class="fa fa-lock" aria-hidden="true"></i>
                      <input autoComplete="off" type="password" id="password" placeholder="Password" defaultValue=""
                        onChange={(e) => {
                          if (e.target.value && this.state.error) {
                            return this.setState({
                              error: false,
                            })
                          };
                        }}
                      />
                    </div>

                    {this.state.error &&
                      <div className="help-block text-center error-msg">{this.state.errorMessage}</div>
                    }

                    <a id="login-button" className="login-btn" href
                      onClick={() => {
                        let email = document.getElementById('email').value;
                        let pass = document.getElementById('password').value;
                        if (!email || !pass) {
                          return this.setState({
                            error: true,
                            errorMessage: 'Email/Username and Password are required.',
                          });
                        }
                        this.props.app.broncoLogin(email, pass, '', this);
                      }}
                    ><i class="fa fa-sign-in"></i> Login</a>

                    <a className="login-link" href
                      onClick={() => {
                        this.setState({
                          mode: 'FORGOT_PASSWORD',
                          error: false,
                        });
                      }}
                    >Forgot Password?
                    </a>
                    <a className="login-link-light" href onClick={() => {
                      this.setState({
                        mode: 'LOGIN_HELP',
                        error: false,
                      });
                    }}
                    >Having trouble logging in?</a>
                  </div>
                </div>
                {this.renderLoginSidebar()}
              </div>

            }

            {/* FORGOT PASSWORD */}

            {this.state.mode === 'FORGOT_PASSWORD' &&

              <div className="login-mode-container">
                <div className="login-mode">
                  <div className="login-card">
                    <div className="login-logo">
                      <img src="/assets/images/login-logo.png" alt="login" />
                    </div>

                    <div className="login-form">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      <input id="forgotPassword" autoComplete="off" type="text" name="forgotPassword" placeholder="Email" defaultValue="" />
                    </div>

                    {this.state.error &&
                      <div className="help-block text-center success-msg">{this.state.successMessage}</div>
                    }

                    {this.state.error &&
                      <div className="help-block text-center error-msg">{this.state.errorMessage}</div>
                    }

                    <a className="login-btn" href
                      onClick={() => {
                        let forgotPassword = document.getElementById('forgotPassword').value;
                        if (!forgotPassword) {
                          return this.setState({
                            error: true,
                            errorMessage: 'Please, enter your email address.',
                          });
                        }

                        const formData = new FormData();
                        formData.append("email", forgotPassword)

                        let url = `https:${BRONCO_PREFIX}/password/forgot`;
                        new kali({
                          form: formData,
                        }).post(url, {
                          success: (_kali, res, data) => {
                            console.log('Forgot Password email was sent!', data);
                            return this.setState({
                              error: true,
                              successMessage: 'If the email entered is found associated to an account we will email you password reset instructions.',
                            });
                          },

                          failure: (err) => {
                            console.error(err);
                            return this.setState({
                              error: true,
                              errorMessage: 'Something went wrong.',
                            });
                          },
                        })

                      }}
                    >
                      <i class="fa fa-paper-plane" aria-hidden="true"></i> Submit
                    </a>

                    <a className="login-link" href
                      onClick={() => {
                        this.setState({
                          mode: 'LOGIN',
                          error: false,
                        });
                      }}
                    ><i class="fa fa-arrow-left"></i> Go back</a>
                    <a className="login-link-light" href onClick={() => {
                      this.setState({
                        mode: 'LOGIN_HELP',
                        error: false,
                      });
                    }}
                    >Having trouble logging in?</a>
                  </div>
                </div>
                {this.renderLoginSidebar()}
              </div>

            }

            {/* RESET PASSWORD */}

            {this.state.mode === 'RESET_PASSWORD' &&

              <div className="login-mode-container">
                <div className="login-mode">
                  <div className="login-card">
                    <div className="login-logo">
                      <img src="/assets/images/login-logo.png" alt="logo" />
                    </div>
                    <p>Reset your current password or create a new one by entering the desired password below.</p>
                    <div className="login-form">
                      <i class="fa fa-lock" aria-hidden="true"></i>
                      <input id="newPassword" autoComplete="off" type="password" name="newPassword" placeholder="Password" defaultValue="" />
                    </div>

                    <div className="login-form">
                      <i class="fa fa-lock" aria-hidden="true"></i>
                      <input id="confirmNewPassword" autoComplete="off" type="password" name="confirmNewPassword" placeholder="Confirm password" defaultValue="" />
                    </div>

                    {this.state.error &&
                      <div className="help-block text-center error-msg">{this.state.errorMessage}</div>
                    }

                    <a className="login-btn" href
                      onClick={() => {
                        let newPass = document.getElementById('newPassword').value;
                        if (!newPass) {
                          return this.setState({
                            error: true,
                            errorMessage: 'Please, enter your new password.',
                          });
                        }

                        let confirmNewPass = document.getElementById('confirmNewPassword').value;
                        if (!confirmNewPass) {
                          return this.setState({
                            error: true,
                            errorMessage: 'Please, confirm your new password.',
                          });
                        }

                        if (newPass !== confirmNewPass) {
                          return this.setState({
                            error: true,
                            errorMessage: 'Passwords must match.',
                          });
                        }

                        const formData = new FormData();
                        formData.append("pass", newPass);

                        let url = `https:${BRONCO_PREFIX}/password/reset?auth_code=${this.state.authCode}`;
                        new kali({
                          form: formData,
                        }).post(url, {
                          success: (_kali, res, data) => {
                            return window.location.pathname = '/login';
                          },

                          failure: (err) => {
                            console.error(err);
                            return this.setState({
                              error: true,
                              errorMessage: 'Something went wrong.',
                            });
                          },
                        })
                      }}
                    >
                      <i class="fa fa-lock" aria-hidden="true"></i> Set Password
                  </a>

                    <a className="login-link" href
                      onClick={() => {
                        this.setState({
                          mode: 'LOGIN',
                          error: false,
                        });
                      }}
                    ><i class="fa fa-arrow-left"></i> Go back</a>
                    <a className="login-link-light" href onClick={() => {
                      this.setState({
                        mode: 'LOGIN_HELP',
                        error: false,
                      });
                    }}
                    >Having trouble logging in?</a>
                  </div>
                </div>
                {this.renderLoginSidebar()}
              </div>

            }

            {/* TWO-FACTOR SETUP */}

            {this.state.mode === '2FA' &&

              <div className="login-mode-container">

                {twoFactorSetup &&
                  <div className="fullscreen-login-mode">
                    <div className="login-fullscreen two-factor-setup">
                      <div className="login-fullscreen-column1">
                        <h2>Two-factor Authentication</h2>
                        <p>You have enabled two-factor authentication for your account. Follow the instructions to complete the setup.</p>
                        <div className="two-factor-qrcode">
                          <img src={'data:image/png;base64,' + window.sessionStorage['2fa_img']} />
                        </div>
                      </div>
                      <div className="login-fullscreen-column2">
                        <ul>
                          <li>
                            <span>1. </span>Download an authenticator app. <br />(We recommend <a target="_blank" href="https://support.google.com/accounts/answer/1066447">Google Authenticator</a>)
                          </li>
                          <li>
                            <span>2. </span>Scan this QR Code.
                          </li>
                          <li>
                            <span>3. </span>Enter verification code.
                          </li>
                        </ul>
                        <div className="verification-code">
                          <div className="verification-boxes">
                            <div id="box1" className="box"></div>
                            <div id="box2" className="box"></div>
                            <div id="box3" className="box"></div>
                            <span>&mdash;</span>
                            <div id="box4" className="box"></div>
                            <div id="box5" className="box"></div>
                            <div id="box6" className="box"></div>
                          </div>
                          <input autoComplete="off" type="text" id="verificationCode" placeholder="Verification code" defaultValue="" maxLength="6" />
                        </div>
                        {this.state.error &&
                          <div className="help-block text-center error-msg">{this.state.errorMessage}</div>
                        }

                        <div id="verifyButton">
                          <a className="login-btn" href
                            onClick={(e) => {
                              e.preventDefault();

                              if (!this.state.email || !this.state.password) {
                                return this.setState({
                                  error: true,
                                  errorMessage: 'Email/Username and Password are required.',
                                });
                              }

                              let auth2FACode = document.getElementById('verificationCode').value;
                              this.props.app.broncoLogin(this.state.email, this.state.password, auth2FACode, this);
                            }}
                          ><i className="fa fa-check"></i> Verify</a>
                        </div>
                      </div>
                      <div className="two-factor-links">
                        <a className="login-link" href
                          onClick={() => {
                            this.setState({
                              mode: 'LOGIN',
                              error: false,
                            });
                          }}
                        >
                          <i class="fa fa-arrow-left"></i> Go back
                      </a>
                        <a className="login-link-light" href onClick={() => {
                          this.setState({
                            mode: 'LOGIN_HELP',
                            error: false,
                          });
                        }}
                        >Having trouble logging in?</a>
                      </div>
                    </div>


                  </div>
                }

                {!twoFactorSetup &&
                  <div className="login-mode">
                    <div className="login-card">
                      <img src="/assets/images/two-factor.png" alt="logo" />
                      <div className="verification-code">
                        <p>Enter your verification code below.</p>
                        <div className="verification-boxes">
                          <div id="box1" className="box"></div>
                          <div id="box2" className="box"></div>
                          <div id="box3" className="box"></div>
                          <span>&mdash;</span>
                          <div id="box4" className="box"></div>
                          <div id="box5" className="box"></div>
                          <div id="box6" className="box"></div>
                        </div>
                        <input autoComplete="off" type="text" id="verificationCode" placeholder="Verification code" defaultValue="" maxLength="6" />
                      </div>
                      {this.state.error &&
                        <div className="help-block text-center error-msg">{this.state.errorMessage}</div>
                      }

                      <div id="verifyButton">
                        <a className="login-btn" href
                          onClick={(e) => {
                            e.preventDefault();

                            if (!this.state.email || !this.state.password) {
                              return this.setState({
                                error: true,
                                errorMessage: 'Email/Username and Password are required.',
                              });
                            }

                            let auth2FACode = document.getElementById('verificationCode').value;
                            this.props.app.broncoLogin(this.state.email, this.state.password, auth2FACode, this);
                          }}
                        ><i className="fa fa-check"></i> Verify</a>
                      </div>

                      <div className="two-factor-links">
                        <a className="login-link" href
                          onClick={() => {
                            this.setState({
                              mode: 'LOGIN',
                              error: false,
                            });
                          }}
                        >
                          <i class="fa fa-arrow-left"></i> Go back
                        </a>
                        <a className="login-link-light" href onClick={() => {
                          this.setState({
                            mode: 'LOGIN_HELP',
                            error: false,
                          });
                        }}
                        >Having trouble logging in?</a>
                      </div>
                    </div>
                  </div>
                }
                {this.renderLoginSidebar()}
              </div>
            }

            {this.state.mode === 'LOGIN_HELP' &&

              <div className="login-mode-container">

                <div className="fullscreen-login-mode">

                  <div className="login-fullscreen">
                    <div className="login-fullscreen-column1">
                      <h2>Browsers that are supported by TouchSource</h2>
                      <p>You can use TouchSource’s portal with popular browsers like Chrome, Firefox, and Safari. We also work with Internet Explorer and Microsoft Edge however we suggest moving to our <strong>No. 1</strong> supported browser (<a target="_blank" href="https://www.google.com/chrome/">Chrome</a>). Our portal works best in the newest and last prior version of these browsers.</p>
                    </div>
                    <div className="login-fullscreen-column2">
                      <p><strong>Supported Browsers:</strong><br />(In order of most to least preferred.</p>
                      <ul>
                        <li><span>1. <a target="_blank" href="https://www.google.com/chrome/">Chrome</a></span><br />Best experience + security updates.</li>
                        <li><span>2. <a target="_blank" href="https://www.mozilla.org/en-US/exp/firefox/new/">Firefox</a> / <a target="_blank" href="https://www.apple.com/safari/">Safari</a></span></li>
                        <li><span>3. <a target="_blank" href="https://www.microsoft.com/en-us/windows/microsoft-edge">Microsoft Edge</a></span></li>
                        <li><span>4. Internet Explorer</span></li>
                      </ul>
                    </div>

                    <h5><strong>Note:</strong> We have received notifications of login issues when using IE 11 or lower. We suggest trying an alternative browser.</h5>

                    <a className="login-link" href
                      onClick={() => {
                        this.setState({
                          mode: 'LOGIN',
                          error: false,
                        });
                      }}
                    >
                      <i class="fa fa-arrow-left"></i> Go back
                    </a>

                  </div>

                </div>

              </div>

            }

          </div>
        </form>

      </Fragment>
    )
  }
}

export default Login;
