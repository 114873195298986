import React, { Component, Fragment } from 'react';
import {
  NavLink,
  Link,
  Redirect,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import kali from 'kali';

import Form, {
  buildSelectData,
  getFormFieldsStatus,
  getFormFieldChanges,
} from '../../form/Form.js';

import {
  getSectionFieldsByFormName,
} from '../../data/fieldData.js';

import { formatDateShort } from '../../form/field/Date.js';

let navData = [
  {
    defaultLabel: 'Management Contact Info',
    route: 'managementContactInfo',
    id: 'managementContactInfoClick',
    ajaxRoute: 'updateUsers',
  },
  {
    defaultLabel: 'Edit Billing',
    route: 'editBilling',
    id: 'editBillingClick',
    ajaxRoute: 'editBilling',
  },
  {
    defaultLabel: 'Renew Account',
    route: 'renew',
    id: 'renewAccountClick',
    ajaxRoute: 'updateUsers',
  },
  {
    defaultLabel: 'Request A Change',
    route: 'requestAChange',
    id: 'requestChangeClick',
    ajaxRoute: 'updateUsers',
  },
];

const API_PREFIX = process.env.REACT_APP_API_PREFIX;

const DEBUG = false;

// Get IE or Edge browser version
let version = detectIE();

let internetExplorer = false;

if (version === false) {
  console.log('Browser is Not IE or Edge')
} else if (version >= 12) {
  console.log('Browser is Edge')
} else {
  console.log('Browser is Internet Explorer')
  internetExplorer = true
}

function detectIE() {
  let ua = window.navigator.userAgent;

  let msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  let trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  let edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      section: this.props.portalSection,
      contentData: {},
      sectionData: [],
      editIndex: 0,
      selectedProperty: parseInt(sessionStorage['selected_property_number']) || false,
      usersData: this.props.usersData || {},
      showPrintOption: true,
      modalError: false,
      modalSuccess: false,
      modalErrorMessage: '',
      modalSuccessMessage: ''
    };

    if (this.props.portalSection === 'renew') {
      this.state.pendingChanges = true;
    }
  }

  print() {
    window.print();
  }

  componentDidMount() {
    let sectionData = [];
    sectionData.push(this.props.usersData || {});

    this.setState({
      sectionData,
      usersData: this.props.usersData,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.usersData !== this.props.usersData) {
      let sectionData = [];
      sectionData.push(this.props.usersData || {});

      this.setState({
        sectionData,
        usersData: this.props.usersData,
      });
    }

    if (prevProps.portalSection !== this.props.portalSection) {
      if (this.props.portalSection === 'renew') {
        this.setState({
          invoice: false,
          pendingChanges: true,
        });
      } else {
        this.setState({
          invoice: false,
          pendingChanges: false,
        });
      }
    }
  }

  onRequestAChange(formData) {
    formData.append("to", "revisions@touchsource.com");
    formData.append("from", formData.get("email"));

    let url = `https:${API_PREFIX}/legacy/emailRequest/`;
    let { headers } = this.props.app.signBroncoURL(url);
    console.log(url);
    new kali({
      headers,
      form: formData,
    }).post(url, {
      success: (_kali, res, data) => {
        // TODO: something to let them know it sent

        this.setState({
          pendingChanges: false,
          modalSuccess: true,
          modalSuccessMessage: 'Your request has been sent.',
          modalError: false,
          modalErrorMessage: ''
        }, (e) => {
          // HACK: make it look like it did something
          window.setTimeout(() => {
            this.props.portal.setState({
              loading: false,
            });
          }, 500);
        });
        console.log('created/updated');
      },

      failure: (_kali, err) => {
        // HACK: make it look like it did something
        window.setTimeout(() => {
          this.props.portal.setState({
            loading: false,
          }, () => {
            if (err && err.statusText) {
              toast.error(err.statusText, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          });
        }, 500);
      },

      serverError: (_kali, res, err) => {
        // HACK: make it look like it did something
        window.setTimeout(() => {
          this.props.portal.setState({
            loading: false,
          }, () => {
            if (err && err.error) {
              toast.error(err.error, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          });
        }, 500);
      },
    });
  }

  onFormSave(formName, e) {
    let { portal } = this.props;
    let { mode, section } = portal.props;

    let id = `form-${mode}-${section}`;
    if (formName) {
      id = `form-${formName}`;
    }
    let form = document.getElementById(id);
    let formData = new FormData(form);

    let { contentData, sectionData } = this.state;
    let orgData = sectionData[this.state.editIndex];
    let sectionFields = getSectionFieldsByFormName(formName, section, contentData);

    let { valid, fields } = getFormFieldsStatus(mode, section, sectionFields, formName);
    if (!valid) {
      this.setState({
        modalError: true,
        modalErrorMessage: 'Please enter all mandatory fields',
        modalSuccess: false,
        modalSuccessMessage: ''
      });

      return;
    }

    if (section === 'requestAChange') {
      return this.onRequestAChange(formData);
    }

    let pendingChanges = getFormFieldChanges(mode, section, sectionFields, orgData, formName);
    if (Object.keys(pendingChanges).length === 0) {
      if (section === 'renew') {
        this.setState({
          invoice: true,
        });
      }

      return;
    }

    this.setState({
      modalError: false,
      modalErrorMessage: '',
      modalSuccess: false,
      modalSuccessMessage: ''
    });

    let jsonFormData = {};
    let newData = {};
    for (let d of formData.entries()) {
      newData[d[0]] = d[1];
      jsonFormData[d[0]] = d[1];
    }
    jsonFormData['ID'] = parseInt(jsonFormData['ID']);

    this.props.portal.setState({
      loading: true,
    });

    let ajaxRoute = 'updateUsers';
    let url = `https:${API_PREFIX}/legacy/${ajaxRoute}/`;
    let { headers } = this.props.app.signBroncoURL(url);
    console.log(url);
    new kali({
      headers,
      body: jsonFormData,
    }).post(url, {
      success: (_kali, res, data) => {
        let { sectionData } = this.state;
        sectionData[this.state.editIndex] = newData;
        this.props.portal.setState({
          usersData: sectionData[0],
        });

        if (section === 'renew') {
          this.setState({
            invoice: true,
          });
        }

        this.setState({
          pendingChanges: false,
        }, (e) => {
          // HACK: make it look like it did something
          window.setTimeout(() => {
            this.props.portal.setState({
              loading: false,
            });
          }, 500);
        });
      },

      failure: (_kali, err) => {
        // HACK: make it look like it did something
        window.setTimeout(() => {
          this.props.portal.setState({
            loading: false,
          }, () => {
            if (err && err.statusText) {
              toast.error(err.statusText, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          });
        }, 500);
      },

      serverError: (_kali, res, err) => {
        // HACK: make it look like it did something
        window.setTimeout(() => {
          this.props.portal.setState({
            loading: false,
          }, () => {
            let errMessage;
            if (err && err.error) {
              errMessage = err.error;
            }

            if (err && err.data && ~err.data.Message.indexOf('Incorrect string value')) {
              errMessage = 'Invalid character(s) in text field and/or file name.';
            }

            if (errMessage) {
              toast.error(errMessage, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          });
        }, 500);
      },
    });
  }

  renderForm(mode, section) {
    let { invoice } = this.state;

    if (invoice) {
      return;
    }

    let { sectionData } = this.state;
    if (!sectionData || sectionData.length === 0) {
      return;
    }

    // formatDate(this.state.online_expires)
    let accountExpiration = sectionData[this.state.editIndex]['online_expires'] || 'online_expires';
    accountExpiration = formatDateShort(accountExpiration);

    let d = new Date(Date.parse(accountExpiration));
    d.setFullYear(d.getFullYear() + 1);
    let expireDate = formatDateShort(d.toString());

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })

    let webNewsFee = sectionData[this.state.editIndex]['web_news_fee'] || 0;
    let renderWebNewsFee = formatter.format(webNewsFee);
    let onlineFee = sectionData[this.state.editIndex]['online_fee'] || 0;
    let renderOnlineFee = formatter.format(onlineFee);
    let warrantyFee = sectionData[this.state.editIndex]['warranty_fee'] || 0;
    let renderWarrantyFee = formatter.format(warrantyFee);
    let otherFee = sectionData[this.state.editIndex]['other_fee'] || 0;
    let renderOtherFee = formatter.format(otherFee);
    let totalFee = (parseFloat(webNewsFee) + parseFloat(onlineFee) + parseFloat(warrantyFee) + parseFloat(otherFee)).toFixed(2);

    let formName;
    switch (section) {
      case 'managementContactInfo':
        formName = 'account::management_contact_info';
        break;

      case 'editBilling':
        formName = 'account::edit_billing';
        break;

      case 'renew':
        formName = 'account::edit_billing';
        break;

      case 'requestAChange':
        break;

      default:
        console.warn(section, 'is missing?');
    }

    let title;
    for (let data of navData) {
      if (data.route !== section) {
        continue;
      }

      title = data.defaultLabel;
    }

    let gaId = '';

    if (section === 'managementContactInfo') {
      gaId = 'managementContactInfoSubmit';
    }
    if (section === 'editBilling') {
      gaId = 'editBillingSubmit';
    }
    if (section === 'renew') {
      gaId = 'renewAccountSubmit';
    }
    if (section === 'requestAChange') {
      gaId = 'requestChangeSubmit';
    }

    return (
      <Fragment>
        <div className="text-label-info-left full-width-div">
          <h1>{title}</h1>
          <p class="mandatory">* Required Fields</p>

          {(section !== 'renew' && section !== 'requestAChange') &&
            <div class="mandatory-subtitle">
              <span>Click the Submit button below when done.</span>
            </div>
          }

          {section === 'renew' &&
            <Fragment>
              <div className="form-edit-information">
                <div className="text-label-info-left">
                  <label>Account Expiration</label>
                  <div className="text-input-group">
                    {accountExpiration}
                  </div>
                </div>
              </div>
              <div className="form-edit-information">
                <div className="text-label-info-left">
                  <label>TouchSource Foundation</label>
                  <div className="text-input-group">
                    {renderWebNewsFee}
                  </div>
                </div>
              </div>
              <div className="form-edit-information">
                <div className="text-label-info-left">
                  <label>TouchSource Live</label>
                  <div className="text-input-group">
                    {renderOnlineFee}
                  </div>
                </div>
              </div>
              <div className="form-edit-information">
                <div className="text-label-info-left">
                  <label>Hardware Support</label>
                  <div className="text-input-group">
                    {renderWarrantyFee}
                  </div>
                </div>
              </div>
              <div className="form-edit-information">
                <div className="text-label-info-left">
                  <label>Other Fee</label>
                  <div className="text-input-group">
                    {renderOtherFee}
                  </div>
                </div>
              </div>
              <div className="form-edit-information">
                <div className="text-label-info-left">
                  <label><strong>Total</strong></label>
                  <div className="text-input-group">
                    <strong>${totalFee}</strong>
                  </div>
                </div>
              </div>
              <div className="form-edit-information">
                <div className="text-label-info-left">
                  <label>To Extend Through</label>
                  <div className="text-input-group">
                    {expireDate}
                    <br />
                    <hr />
                  </div>
                </div>
              </div>
              <div className="form-edit-information">
                <div className="">
                  <div className="text-input-group renew-account__message">
                    Please make sure all the billing information below is correct, then click the "Continue to Invoice Page" button. To cancel, please use your browser"s BACK button.
                  </div>
                </div>
              </div>
            </Fragment>
          }

          {section === 'requestAChange' &&
            <div class="mandatory-subtitle">
              <span>Please enter the following information, being as specific as possible on your request. One of our team members will respond within the next 24 hours.</span>
            </div>
          }

          {this.state.modalError &&
            <div className="left-float error-msg">{this.state.modalErrorMessage}</div>
          }

          {this.state.modalSuccess &&
            <div className="left-float success-msg">{this.state.modalSuccessMessage}</div>
          }

          <Form
            parent={this}

            contentData={this.state.contentData}
            editIndex={this.state.editIndex}
            mode={mode}
            section={section}
            formName={formName}
            sectionData={this.state.sectionData}
            selectData={buildSelectData()}
          />



          {section === 'managementContactInfo' &&
            <div className="form-submit">
              <input
                className={`btn save ${((!this.state.pendingChanges) ? '' : 'pending-changes')}`}
                type="button"
                value="Submit"
                onClick={this.onFormSave.bind(this, formName)}
                id={gaId}
              />
            </div>
          }

          {section === 'editBilling' &&
            <div className="form-submit">
              <input
                className={`btn save ${((!this.state.pendingChanges) ? '' : 'pending-changes')}`}
                type="button"
                value="Submit"
                onClick={this.onFormSave.bind(this, formName)}
                id={gaId}
              />
            </div>
          }


          {section === 'renew' &&
            <div className="form-submit">
              <input
                className={`btn save ${((!this.state.pendingChanges) ? '' : 'pending-changes')}`}
                type="button"
                value="Continue to Invoice Page"
                onClick={this.onFormSave.bind(this, formName)}
                id={gaId}
              />
            </div>
          }

          {section === 'requestAChange' &&
            <div className="form-submit">
              <input
                className={`btn save ${((!this.state.pendingChanges) ? '' : 'pending-changes')}`}
                type="button"
                value="Submit"
                onClick={this.onFormSave.bind(this, formName)}
                id={gaId}
              />
            </div>
          }

        </div>
      </Fragment>
    )
  }

  renderInvoice(mode, section) {
    let { invoice, sectionData } = this.state;

    if (!invoice) {
      return;
    }

    let today = formatDateShort((new Date()).toString());
    let propNum = sectionData[this.state.editIndex]['prop_num'];
    let invoiceDate = new Date();
    let invoiceYear = invoiceDate.getFullYear();

    let billingCompany = sectionData[this.state.editIndex]['billing_company'];
    let billingContact = sectionData[this.state.editIndex]['billing_contact'];
    let billingAddress1 = sectionData[this.state.editIndex]['billing_address_1'];
    let billingCity = sectionData[this.state.editIndex]['billing_city'];
    let billingState = sectionData[this.state.editIndex]['billing_state'];
    let billingPostal = sectionData[this.state.editIndex]['billing_postal'];

    let accountExpiration = sectionData[this.state.editIndex]['online_expires'];
    accountExpiration = formatDateShort(accountExpiration);

    let d = new Date(Date.parse(accountExpiration));
    d.setFullYear(d.getFullYear() + 1);
    let expireDate = formatDateShort(d.toString());

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })

    let webNewsFee = sectionData[this.state.editIndex]['web_news_fee'] || 0;
    let renderWebNewsFee = formatter.format(webNewsFee);
    let onlineFee = sectionData[this.state.editIndex]['online_fee'] || 0;
    let renderOnlineFee = formatter.format(onlineFee);
    let warrantyFee = sectionData[this.state.editIndex]['warranty_fee'] || 0;
    let renderWarrantyFee = formatter.format(warrantyFee);
    let otherFee = sectionData[this.state.editIndex]['other_fee'] || 0;
    let renderOtherFee = formatter.format(otherFee);
    let totalFee = (parseFloat(webNewsFee) + parseFloat(onlineFee) + parseFloat(warrantyFee) + parseFloat(otherFee)).toFixed(2);

    return (
      <div className="invoice">
        <table cellPadding="2" cellSpacing="2" >
          <tbody>
            <tr>
              <td className="top-v-align"><div className="invbig">
                TouchSource, LLC.<br />
                1370 Miners Drive, Suite 103<br />
                Lafayette, CO  80026<br />
                303-828-4007 (phone)<br />
                720-407-2557 (fax)<br />
              </div></td>
              <td className="top-v-align"><img src="/assets/images/ts-logo.jpg" alt="" width="180" height="48" ></img></td>
              <td className="text-right"><div className="invhead">INVOICE</div>
                <br></br>
                <table className="border" cellPadding="6" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td ><div className="invbig">DATE</div></td>
                      <td><div className="invbig">INVOICE #</div></td>
                    </tr>
                    <tr>
                      <td><div className="invmain">{today}</div></td>
                      <td><div className="invmain">{propNum}-{invoiceYear}</div></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <table cellPadding="2" cellSpacing="2">
          <tbody><tr>
            <td className="width-45">
              <table className="border" cellPadding="6" cellSpacing="0">
                <tbody><tr>
                  <td className="address-h text-left"><div className="invbig">BILL TO:</div></td>
                </tr>
                  <tr>
                    <td className="text-left top-v-align address-v"><div className="invmain">
                      {billingCompany}<br />
                      {billingContact}<br />
                      {billingAddress1}<br />
                      {billingCity}, {billingState}, {billingPostal}<br />
                    </div>
                    </td>
                  </tr>
                </tbody></table>
            </td>
            <td className="width-10">&nbsp;</td>
            <td className="width-45">
              <table className="border" cellPadding="6" cellSpacing="0">
                <tbody><tr>
                  <td className="address-h text-left"><div className="invbig">FOR PROPERTY:</div></td>
                </tr>
                  <tr>
                    <td className="text-left top-v-align address-v"><div className="invmain">
                      {billingCompany}<br />
                      Payment Due Date: {accountExpiration}<br />
                      Payment is for the services listed below, from {accountExpiration} through {expireDate}
                    </div>
                    </td>
                  </tr>
                </tbody></table>
            </td>
          </tr>
          </tbody></table>
        <table cellPadding="2" cellSpacing="2">
          <tbody>
            <tr>
              <td>
                <table className="border" cellPadding="8" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td className="width-25 text-left"><div className="invbig">ITEM</div></td>
                      <td className="width-5 text-center"><div className="invbig">QTY</div></td>
                      <td className="width-50 text-left"><div className="invbig">DESCRIPTION</div></td>
                      <td className="width-10 text-center"><div className="invbig">PRICE EACH</div></td>
                      <td className="width-10 text-center"><div className="invbig">TOTAL PRICE</div></td>
                    </tr>
                    <tr>
                      <td className="width-25 text-left">TouchSource Foundation</td>
                      <td className="width-5 text-center">1</td>
                      <td className="width-50 text-left">Software Support including: <br />
                        -Unlimited phone/email/remote Support <br />
                        -Cloud based Content Management</td>
                      <td className="width-10 text-right">{renderOnlineFee}</td>
                      <td className="width-10 text-right">{renderOnlineFee}</td>
                    </tr>
                    <tr>
                      <td className="width-25 text-left">Hardware Support</td>
                      <td className="width-5 text-center">1</td>
                      <td className="width-50 text-left">Annual Hardware Support</td>
                      <td className="width-10 text-right">{renderWarrantyFee}</td>
                      <td className="width-10 text-right">{renderWarrantyFee}</td>
                    </tr>
                    <tr>
                      <td className="width-25 text-left">TouchSource Live</td>
                      <td className="width-5 text-center">1</td>
                      <td className="width-50 text-left">TouchSource Live Web content</td>
                      <td className="width-10 text-right">{renderWebNewsFee}</td>
                      <td className="width-10 text-right">{renderWebNewsFee}</td>
                    </tr>
                    <tr>
                      <td className="width-25 text-left">Other Fee</td>
                      <td className="width-5 text-center">1</td>
                      <td className="width-50 text-left">Other Misc Fee</td>
                      <td className="width-10 text-right">{renderOtherFee}</td>
                      <td className="width-10 text-right">{renderOtherFee}</td>
                    </tr>

                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table cellPadding="2" cellSpacing="2">
          <tbody><tr>
            <td>
              <table className="border" cellPadding="8" cellSpacing="0">
                <tbody><tr>
                  <td className="text-left width-80"><div className="invmain">Thank you.  We appreciate your business!</div></td>
                  <td className="text-right width-20"><span className="invbig">TOTAL</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>${totalFee}</span></td>
                </tr>
                </tbody></table>
            </td>
          </tr>
          </tbody></table>
        <table cellPadding="2" cellSpacing="2">
          <tbody><tr>
            <td>
              <table cellPadding="8" cellSpacing="0">
                <tbody><tr>
                  <td className="text-center"><div className="invmain"><i>
                    <span className="error-msg">IMPORTANT:</span>&nbsp;&nbsp;Please make checks payable to "TouchSource, LLC." and remit to address above.
              &nbsp;<br></br>Your account will be extended when we receive your check. All pricing in U.S. Dollars</i><br></br><br></br>
                  </div>
                    <br></br>
                    <div className="printOnly">
                      <table cellPadding="8" cellSpacing="0">
                        <tr>
                          <td className="text-center">
                            <p>If you would prefer to pay by credit card, please fill in the information below<br></br>
                              and fax this form to 720-407-2557.  We accept AMEX, Visa and M/C</p>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="printOnly">
                      <table className="border" cellPadding="2" cellSpacing="0">
                        <tbody>
                          <tr className="top-v-align">
                            <td className="text-left address-h"><div className="invsmall">CREDIT CARD NUMBER:</div></td>
                            <td className="text-left address-h"><div className="invsmall">3 DIGIT SECURITY CODE ON BACK OF CARD</div></td>
                          </tr>
                          <tr className="top-v-align">
                            <td className="text-left address-h"><div className="invsmall">EXPIRATION DATE:</div></td>
                            <td className="text-left address-h"><div className="invsmall">PRINTED NAME (as it appears on the card):</div></td>
                          </tr>
                          <tr className="top-v-align">
                            <td className="text-left address-h"><div className="invsmall">ZIP CODE FOR BILLNG ADDRESS OF CARD:</div></td>
                            <td className="text-left address-h"><div className="invsmall">SIGNATURE:</div></td>
                          </tr>

                        </tbody></table>
                    </div>
                    <br></br>
                    <div className="invoice-buttons">
                      {
                        this.state.showPrintOption === true ?
                          <button className="btn webOnly" name="print.jpg" onClick={() => { this.print() }}>Print Invoice</button> : ""
                      }
                    </div>

                  </td>
                </tr>
                </tbody></table>
            </td>
          </tr>
          </tbody></table>

        {/* <Dialog open={this.state.open}>
          <div className="invoice-modal">
            <div onClick={this.closePayByCreditCard}>
              <button className="invoice-modal__close">
                <i className="fa fa-window-close" />
              </button>
            </div>
            <div className="invoice-modal__body">
              <h2><i className="fa fa-credit-card" /> Coming soon!</h2>
              <p>We are actively working on this exciting new feature. We will let you know when this has been released.</p>
              <button id="creditCardInterest" className="btn" onClick={this.sayThankYou}>I am interested in this feature</button>
            </div>
          </div>
          <Dialog open={this.state.active}>
            <div className="thankyou-modal">
              <div onClick={this.closePayByCreditCard}>
                <button className="invoice-modal__close">
                  <i className="fa fa-window-close" />
                </button>
              </div>
              <div>
                <p>Thank you for your interest in paying by credit card.</p>
              </div>
            </div>
          </Dialog>
        </Dialog> */}


      </div>
    )
  }

  render() {
    if (this.state.redirect && window.location.pathname !== this.state.redirectTo) {
      return (
        <Redirect
          push={false}
          to={this.state.redirectTo}
        />
      )
    }

    let { portal } = this.props;
    let { mode, section } = portal.props;

    const submitChangesButton = () => {
      if (this.state.usersData.update_type == "W") {
        return <div className="publish-button">
          <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
            <i className="fa fa-question-circle"
              onMouseOver={() => {
                this.setState({
                  publishTooltipActive: true
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  publishTooltipActive: false
                });
              }}
            ></i>
            {this.state.publishTooltipActive &&
              <div className="tooltip-message">
                Content will be updated and downloaded.
                <div className="tooltip-triangle"></div></div>
            }
          </div>
          <button
            type="button"
            className="submitChangesButton btn right-float"
            onClick={() => {
              this.props.app.fetchBarbarian(this.state.usersData.update_type);
            }}
          ><i class="fa fa-sign-out"></i> Publish Changes and Download</button>
        </div>
      }
      if (this.props.portalSection === 'slideshow') {
        if (this.state.contentData.video_upload === '1' && (this.state.contentData.multiple_bldg === '1')) {
          return <div className="publish-button">
            <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
              <i className="fa fa-question-circle"
                onMouseOver={() => {
                  this.setState({
                    publishTooltipActive: true
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    publishTooltipActive: false
                  });
                }}
              ></i>
              {this.state.publishTooltipActive &&
                <div className="tooltip-message">
                  Systems will update slideshow content immediately.
                <div className="tooltip-triangle"></div></div>
              }
            </div>
            <button
              type="button"
              className="submitChangesButton btn right-float disabled"
            ><i class="fa fa-image"></i> Changes Made Live</button>
          </div>
        }
      }
      if (this.props.portalSection === 'slideshow') {
        if (this.state.contentData.video_upload !== '1' || (this.state.contentData.multiple_bldg !== '1')) {
          return <div className="publish-button">
            <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
              <i className="fa fa-question-circle"
                onMouseOver={() => {
                  this.setState({
                    publishTooltipActive: true
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    publishTooltipActive: false
                  });
                }}
              ></i>
              {this.state.publishTooltipActive &&
                <div className="tooltip-message">
                  Systems will update their content overnight.
                <div className="tooltip-triangle"></div></div>
              }
            </div>
            <button
              type="button"
              className="submitChangesButton btn right-float"
              onClick={() => {
                this.props.app.fetchBarbarian(this.state.usersData.update_type);
              }}
            ><i class="fa fa-sign-out"></i> Publish Changes</button>
          </div>
        }
      }
      else {
        return <div className="publish-button">
          <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
            <i className="fa fa-question-circle"
              onMouseOver={() => {
                this.setState({
                  publishTooltipActive: true
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  publishTooltipActive: false
                });
              }}
            ></i>
            {this.state.publishTooltipActive &&
              <div className="tooltip-message">
                Systems will update their content overnight.
                <div className="tooltip-triangle"></div></div>
            }
          </div>
          <button
            type="button"
            className="submitChangesButton btn right-float"
            onClick={() => {
              this.props.app.fetchBarbarian(this.state.usersData.update_type);
            }}
          ><i class="fa fa-sign-out"></i> Publish Changes</button>
        </div>
      }
    }

    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    let expireDate = 0;
    if (this.state.usersData.online_expires) {
      expireDate = new Date(Date.parse(this.state.usersData.online_expires));
      expireDate.setHours(0);
      expireDate.setMinutes(0);
      expireDate.setSeconds(0);
    }

    let diff = Math.round((expireDate - now) / 1000 / 60 / 60 / 24);
    if (diff < 1 || diff > 90) {
      diff = false;
    }

    return (
      <Fragment>
        <div className="header-bottom">

          {internetExplorer &&
            <div className="ie-message">
              <span>We noticed you are using Internet Explorer.</span>
              To have the best portal experience, please switch to the <a target="_blank" href="https://www.google.com/chrome/">Chrome web browser</a>.
            </div>
          }

          {(this.state.usersData.online_expires && this.state.usersData.reseller === '') &&
            <div className="renew-message">
              {diff &&
                <Fragment>
                  <span className="renew-icon message-icon"></span>
                  Please renew your account within {diff} days!
                  <Link to={`/dashboard/account/renew`}><span className="p-l-10">Renew</span></Link>
                </Fragment>
              }
            </div>
          }

          {this.state.usersData.archive === '1' &&
            <div className="renew-message">
              <span className="renew-icon message-icon"></span> Your account has been suspended, please process payment to unlock you account.
            </div>
          }

          {submitChangesButton()}
        </div>

        <div className={`content-body ${mode} ${section}`}>
          <div className="left-navigation">
            <select
              value={section}
              onChange={(e) => {
                this.setState({
                  redirect: true,
                  redirectTo: `/dashboard/account/${e.target.value}`,
                });
              }}
            >
              {navData.map((data) => {
                if (!data) {
                  return false;
                }

                return (
                  <option
                    key={data.route}
                    value={data.route}
                  >
                    {this.state.contentData[data.labelKey] || data.defaultLabel}
                  </option>
                )
              })}
            </select>

            <ul>
              {navData.map((data) => {
                if (!data) {
                  return false;
                }

                let activeClassName = 'active';
                if (section !== data.route) {
                  activeClassName = '';
                }

                if (internetExplorer === true) {
                  return (
                    <li
                      key={data.route}
                      className={activeClassName}
                      id={data.id}
                    >
                      <NavLink
                        to={`/dashboard/account/${data.route}`}
                        activeClassName='active'
                        id={data.id}
                      >{this.state.contentData[data.labelKey] || data.defaultLabel}</NavLink>
                    </li>
                  )
                }

                return (
                  <li
                    key={data.route}
                    className={activeClassName}
                  >
                    <NavLink
                      to={`/dashboard/account/${data.route}`}
                      activeClassName='active'
                    >{this.state.contentData[data.labelKey] || data.defaultLabel}</NavLink>
                  </li>
                )
              })}
            </ul>
          </div>

          <div className={`content-section ${mode} ${section}`}>
            {this.renderForm(mode, section)}
            {this.renderInvoice(mode, section)}

            {DEBUG &&
              <div
                className="debug-raw-data-box"
              >
                <pre>
                  <code>
                    COUNT: {this.state.sectionData.length + '\n'}
                    {JSON.stringify(this.state.sectionData, null, 2)}
                  </code>
                </pre>
              </div>
            }
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Account;
