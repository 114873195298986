import React, { Component, Fragment } from 'react';

class Text extends Component {
  state = {
    value: this.props.value,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }

    if (prevProps.clearForm !== this.props.clearForm) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  renderField(type, field, readOnly) {
    return (
      <input
        type={type}
        name={field.key}
        id={`field-${field.key}`}
        // readOnly={readOnly}

        value={this.state.value || field.value || ''}
        onChange={((e) => {
          let val = e.target.value;
          if (field.type === 'number') {
            if (val < 0) {
              val = 0;
            }
          }
          if (e.target.value === '') {
            val = '';
          }

          this.setState({
            value: val,
          });

          if (this.props.inline) {
            let formField = document.querySelector(`#${this.props.formId} [name="${field.key}"]`);
            formField.value = val;
          } else {
            this.props.form.onChange();

            if (field.required && (!val || val === '')) {
              e.target.classList.add('invalid');
              e.target.classList.remove('valid');
            }

            if (field.required && (val || val !== '')) {
              e.target.classList.remove('invalid');
              e.target.classList.add('valid');
            }
          }
        })}
      />
    )
  }

  render() {
    let { label, field, hidden, debug } = this.props;
    if (hidden) {
      if (!label) {
        label = field.key;
      }
    }

    let type = 'text';
    if (field.type === 'number') {
      type = 'number';
    }

    let readOnly = false;
    if (hidden) {
      type = 'hidden';
      if (debug) {
        hidden = false;
        type = 'text';
      }

      readOnly = true;
    }

    if (this.props.inline) {
      return this.renderField(type, field, readOnly);
    }

    return (
      <Fragment>
        {!hidden &&
          <label>{label}</label>
        }
        {this.renderField(type, field, readOnly)}
      </Fragment>
    )
  }
}

export default Text;