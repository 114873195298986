import React, { Component, Fragment } from 'react';

class Radio extends Component {
  state = {
    value: this.props.value,
    checked: (this.props.value === '1'),
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
        checked: (this.props.value === '1'),
      });
    }

    if (prevProps.clearForm !== this.props.clearForm) {
      this.setState({
        value: this.props.value,
        checked: (this.props.value === '1'),
      });
    }
  }

  handleChange(e) {
    let checked = false;
    if (e.target.value === '1') {
      checked = true;
    }

    this.setState({
      checked,
    });
    this.props.form.onChange();
  }

  render() {
    let { label, field } = this.props;

    return (
      <Fragment>
        <label>{label}</label>
        <div className="text-input-group">
          <input type="radio" name={field.key} value="1" checked={this.state.checked} onChange={this.handleChange.bind(this)} /> Yes
          <input type="radio" name={field.key} value="0" checked={!this.state.checked} onChange={this.handleChange.bind(this)} /> No
        </div>
      </Fragment>
    )
  }
}

export default Radio;