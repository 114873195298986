import React, { Component, Fragment } from 'react';
import { toast } from "react-toastify";

const PDF_PATH = 'http://updates.touchsource.com/Updates/PROP_NUM/Common/Program/';

class PDFUpload extends Component {
  state = {
    orgName: this.props.value,
    name: this.props.value,
    newFileSelectedForUpload: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        orgName: this.props.value,
        name: this.props.value,
        newFileSelectedForUpload: false,
      });
    }

    if (prevProps.clearForm !== this.props.clearForm) {
      this.setState({
        orgName: this.props.value,
        name: this.props.value,
        newFileSelectedForUpload: false,
      });
    }
  }

  render() {
    let { label, field } = this.props;
    let id = `form-field-${field.key}`;
    if (this.props.form.props.formHasNoID) {
      id = '';
    }

    let name = this.state.name;
    if (name) {
      name = name.substr((name.indexOf('_') + 1), name.length);
    }

    if (!this.state.miniMode) {
      if (!name) {
        name = 'Please select PDF file';
      }
    }

    let fieldKey = field.fileKey || field.key;
    if (!this.state.newFileSelectedForUpload) {
      fieldKey = '';
    }

    let propNum = sessionStorage['selected_property_number'];
    let href = `${PDF_PATH}${this.state.name}`.replace('PROP_NUM', propNum);
    if (!this.state.name) {
      // TODO: render <a> or <span> if uploaded or not
      href = '#';
    }

    return (
      <Fragment>
        <label>{label}</label>

        {!this.state.miniMode &&
          <div className="edit-form-image">
            <div className="pdf-upload">
              <i className="fa fa-file-pdf-o"></i>
              <br />
              <span className="text-ellipsis">{name}</span>
            </div>
          </div>
        }

        {this.state.miniMode &&
          <div className="edit-form-image edit-form-image-align">
            <i className="fa fa-file-pdf-o"></i>
            <br />
            <span className="text-ellipsis">{name}</span>
          </div>
        }


        <div className="edit-form-button-group">
          {/* 
            no new file selected for upload 
            so we store the data in a hidden field as a string
          */}
          {!this.state.newFileSelectedForUpload &&
            <input type="hidden" name={field.key} value={this.state.name || ''} readOnly />
          }

          <input type="file" id={id} name={fieldKey} style={{ display: 'none' }}
            onChange={(e) => {
              let file = e.target.files[0];
              if (!file) {
                this.props.form.onChange();
                return;
              }

              if (file.type === 'application/pdf') {
                this.setState({
                  name: file.name,
                  newFileSelectedForUpload: true, // WARN: could be same selected file
                });

                window.setTimeout(() => {
                  this.props.form.onChange();
                }, 100);
              } else {
                toast.error("Please use the PDF file format.", {
                  position: toast.POSITION.TOP_RIGHT
                });
              }
            }}
          />

          <button className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              document.getElementById(id).click();
            }}
          >
            {(this.state.name) ? 'Replace PDF' : 'Upload PDF'}
          </button>

          {this.state.name &&
            <button className="btn btn-secondary"
              onClick={(e) => {
                e.preventDefault();

                this.setState({
                  name: '',
                  newFileSelectedForUpload: false,
                }, () => {
                  document.getElementById(id).value = '';
                  this.props.form.onChange();
                });
              }}
            >Delete PDF</button>
          }
        </div>
      </Fragment>
    )
  }
}

export default PDFUpload;