import React, { Component, Fragment } from 'react';
import {
    NavLink,
    Redirect,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import kali from 'kali';

const API_PREFIX = process.env.REACT_APP_API_PREFIX;

const DEBUG = false;

class Help extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contentData: {},
        };
    }

    componentDidMount() {
        this.props.portal.setState({
            loading: false,
        });
    }

    render() {
        if (this.state.redirect && window.location.pathname !== this.state.redirectTo) {
            return (
                <Redirect
                    push={false}
                    to={this.state.redirectTo}
                />
            )
        }

        let { portal } = this.props;

        return (
            <Fragment>
                <div className="help-page">
                    <div className="help-page__title">
                        <h1>Help Center</h1>
                        <p>Can't find what you need in our help docs? Get in touch with a member of our team at <a href="tel:866-476-1873">866-476-1873</a>.</p>
                        <a href="/dashboard/support/serviceRequest" className="btn">Submit a Service Request</a>
                    </div>
                    <div className="help-page__content">
                        <div className="card">
                            <h2>
                                <i className="fa fa-user" />Account
                            </h2>
                            <p>Helpful tutorials on account settings.</p>
                            <ul>
                                <GifToggle title="Manage Account" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/manage-account.gif" />
                                <GifToggle title="Renew Account" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/renew-account.gif" />
                                <GifToggle title="Preview Program" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/preview-program.gif" />
                                <GifToggle title="Request A Change" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/request-a-change.gif" />
                                <GifToggle title="Edit Billing Info" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/edit-billing-info.gif" />
                                <GifToggle title="View Invoice" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/view-invoice.gif" />
                                <GifToggle title="Switch Accounts" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/switch-accounts.gif" />
                                <GifToggle title="Logout" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/logout.gif" />
                            </ul>
                        </div>
                        <div className="card">
                            <h2>
                                <i className="fa fa-list" />Lists
                            </h2>
                            <p>Helpful tutorials on adding, editing and deleting companies in the portal.</p>
                            <ul>
                                <GifToggle title="Adding a List Item" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/add-list-item.gif" />
                                <GifToggle title="Editing a List Item (Inline)" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/edit-list-item-inline.gif" />
                                <GifToggle title="Editing a List Item (Modal)" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/edit-list-item-modal.gif" />
                                <GifToggle title="Deleting a List Item" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/delete-list-item.gif" />
                            </ul>
                        </div>
                        <div className="card">
                            <h2>
                                <i className="fa fa-sticky-note" />Release Notes
                            </h2>
                            <p>A list of release notes documenting new features and fixes in each release.</p>
                            <ul>
                                <li>
                                    <a target="_blank" href="/assets/help-center-resources/release-notes/ts-3.0.0.pdf">
                                        <i className="fa fa-file" />3.0.0 Release Notes
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="/assets/help-center-resources/release-notes/ts-3.1.0.pdf">
                                        <i className="fa fa-file" />3.1.0 Release Notes
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="/assets/help-center-resources/release-notes/ts-3.1.1-3.1.2.pdf">
                                        <i className="fa fa-file" />3.1.1 and 3.1.2 Release Notes
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="card">
                            <h2>
                                <i className="fa fa-image" />Slides
                            </h2>
                            <p>Helpful tutorials on setting up your account.</p>
                            <ul>
                                <GifToggle title="Adding a Slide" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/add-slide.gif" />
                                <GifToggle title="Editing a Slide" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/edit-slide.gif" />
                                <GifToggle title="Deleting a Slide" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/delete-slide.gif" />
                                <GifToggle title="Upload PDF" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/upload-pdf.gif" />
                            </ul>
                        </div>
                        <div className="card">
                            <h2>
                                <i className="fa fa-support" />Support
                            </h2>
                            <p>Helpful tutorials on Touchsource support.</p>
                            <ul>
                                <GifToggle title="Get Support Help" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/get-support-help.gif" />
                                <GifToggle title="Submit a Service Request" icon="fa-video-camera" resource="/assets/help-center-resources/gifs/request-a-change.gif" />
                            </ul>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

class GifToggle extends Component {
    state = {
        on: false,
    }

    toggle = () => {
        this.setState({
            on: !this.state.on
        })
    }

    render() {
        return (
            <div>
                {this.state.on && (
                    <div className="help-page__modal">
                        <div className="help-page__modal-content">
                            <button onClick={this.toggle}><i className="fa fa-close" /></button>
                            <h3>{this.props.title}</h3>
                            <div className="help-page__modal-resource">
                                <img src={this.props.resource} />
                            </div>
                        </div>
                    </div>
                )}
                <li onClick={this.toggle}><i className={"fa " + this.props.icon} />{this.props.title}</li>
            </div>
        )
    }
}

export default Help;