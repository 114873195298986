import React, { Component, Fragment } from 'react';
import {
  Link,
  NavLink,
  Redirect,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import kali from 'kali';

let navData = [
  {
    defaultLabel: 'Submit A Service Request',
    route: 'serviceRequest',
  },
  {
    defaultLabel: 'Present Your PC To Support',
    route: 'PCtoSupport',
  },
];

const thankYouUrl = window.location.protocol + `//` + window.location.host + `/dashboard/thankyou`;

const API_PREFIX = process.env.REACT_APP_API_PREFIX;

const DEBUG = false;

// Get IE or Edge browser version
let version = detectIE();

let internetExplorer = false;

if (version === false) {
  console.log('Browser is Not IE or Edge')
} else if (version >= 12) {
  console.log('Browser is Edge')
} else {
  console.log('Browser is Internet Explorer')
  internetExplorer = true
}

function detectIE() {
  let ua = window.navigator.userAgent;

  let msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  let trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  let edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}

class Support extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentData: {},
      supportName: '',
      instructions: false,
      usersData: this.props.usersData || {},
    };
  }

  componentDidMount() {
    this.props.portal.setState({
      loading: false,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.section !== this.props.section) {
      this.setState({
        supportName: '',
        instructions: false,
      });
    }

    if (prevProps.usersData !== this.props.usersData) {
      this.setState({
        usersData: this.props.usersData,
      });

      return;
    }

  }

  getSupport(id, name) {
    this.toggleInstructions(name);

    let url = `http://support.touchsource.com/download_client_connector?id=${id}&name=Support+-+${name}&download=1`;
    window.open(url, '_blank');
  }

  toggleInstructions(name = '') {
    this.setState({
      supportName: name.replace(new RegExp(/\+/, 'g'), ' '),
      instructions: !this.state.instructions,
    });
  }

  renderInstructions() {
    if (!this.state.instructions) {
      return;
    }

    return (
      <div className="support-modal-container">
        <div className="support-modal">
          <div className="support-modal-header">
          <h1>Support Sesssion Instructions</h1>
            <i className="fa fa-window-close" aria-hidden="true" onClick={this.toggleInstructions.bind(this, '')}></i>
          </div>
          <p>You are about to start a support session with <span>{this.state.supportName}</span>.</p>
          <ol>
            <li><b>Click Keep</b> in the lower left corner of this window if you are prompted to Keep or Discard. If not, skip this step.</li>
            <li>
              <b>Click</b> on the file you downloaded in the lower left corner of this window or in your downloads folder.
            </li>
            <li><b>Click Run</b> if you are asked if you want to run this software.</li>
          </ol>
          <p>You may close this window at any time after your session has started.</p>
        </div>
      </div>
    )
  }

  renderSupportList() {
    

    return (
      <Fragment>
        <div className="support-portal">
          <h1>Present Your PC to Support</h1>
          <div className="mandatory-subtitle">
            <span>Download our remote client and start a support session with your chosen representative by clicking their name in the list below.</span>
          </div>
          <ul>
            <li><a href onClick={() => { this.getSupport(13, 'Andy+Dodds') }}>Andy Dodds <i class="fas fa-download"></i></a></li>
            <li><a href onClick={() => { this.getSupport(17, 'Brian+Nelsen') }}>Brian Nelsen <i class="fas fa-download"></i></a></li>
            <li><a href onClick={() => { this.getSupport(2, 'Mark+Morley') }}>Mark Morley <i class="fas fa-download"></i></a></li>
            <li><a href onClick={() => { this.getSupport(8, 'Ryan+Hauswald') }}>Ryan Hauswald <i class="fas fa-download"></i></a></li>
            <li><a href onClick={() => { this.getSupport(26, 'Max+Mitrani') }}>Max Mitrani <i class="fas fa-download"></i></a></li>
          </ul>
        </div>
      </Fragment>
    )
  }

  render() {
    if (this.state.redirect && window.location.pathname !== this.state.redirectTo) {
      return (
        <Redirect
          push={false}
          to={this.state.redirectTo}
        />
      )
    }

    let { portal } = this.props;
    let { mode, section } = portal.props;

    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    let expireDate = 0;
    if (this.state.usersData.online_expires) {
      expireDate = new Date(Date.parse(this.state.usersData.online_expires));
      expireDate.setHours(0);
      expireDate.setMinutes(0);
      expireDate.setSeconds(0);
    }

    let diff = Math.round((expireDate - now) / 1000 / 60 / 60 / 24);
    if (diff < 1 || diff > 90) {
      diff = false;
    }

    const submitChangesButton = () => {
      if (this.state.usersData.update_type == "W") {
        return <div className="publish-button">
            <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
              <i className="fa fa-question-circle"
              onMouseOver={() => {
                this.setState({
                  publishTooltipActive: true
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  publishTooltipActive: false
                });
              }}
              ></i>
              {this.state.publishTooltipActive &&
                <div className="tooltip-message">
                Content will be updated and downloaded.
                <div className="tooltip-triangle"></div></div>
              }
            </div>
          <button
            type="button"
            className="submitChangesButton btn right-float"
            onClick={() => {
              this.props.app.fetchBarbarian(this.state.usersData.update_type);
            }}
          ><i class="fa fa-sign-out"></i> Publish Changes and Download</button>
        </div>
      }
      if (this.props.portalSection === 'slideshow') {
        if (this.state.contentData.video_upload === '1' && (this.state.contentData.multiple_bldg === '1')) {
          return <div className="publish-button">
            <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
              <i className="fa fa-question-circle"
              onMouseOver={() => {
                this.setState({
                  publishTooltipActive: true
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  publishTooltipActive: false
                });
              }}
              ></i>
              {this.state.publishTooltipActive &&
                <div className="tooltip-message">
                Systems will update slideshow content immediately.
                <div className="tooltip-triangle"></div></div>
              }
            </div>
            <button
              type="button"
              className="submitChangesButton btn right-float disabled"
            ><i class="fa fa-image"></i> Changes Made Live</button>
          </div>
        }
      }
      if (this.props.portalSection === 'slideshow') {
        if (this.state.contentData.video_upload !== '1' || (this.state.contentData.multiple_bldg !== '1')) {
          return <div className="publish-button">
            <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
              <i className="fa fa-question-circle"
              onMouseOver={() => {
                this.setState({
                  publishTooltipActive: true
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  publishTooltipActive: false
                });
              }}
              ></i>
              {this.state.publishTooltipActive &&
                <div className="tooltip-message">
                Systems will update their content overnight.
                <div className="tooltip-triangle"></div></div>
              }
            </div>
            <button
              type="button"
              className="submitChangesButton btn right-float"
              onClick={() => {
                this.props.app.fetchBarbarian(this.state.usersData.update_type);
              }}
            ><i class="fa fa-sign-out"></i> Publish Changes</button>
          </div>
        }
      }
      else {
        return <div className="publish-button">
          <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
              <i className="fa fa-question-circle"
              onMouseOver={() => {
                this.setState({
                  publishTooltipActive: true
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  publishTooltipActive: false
                });
              }}
              ></i>
              {this.state.publishTooltipActive &&
                <div className="tooltip-message">
                Systems will update their content overnight.
                <div className="tooltip-triangle"></div></div>
              }
            </div>
          <button
            type="button"
            className="submitChangesButton btn right-float"
            onClick={() => {
              this.props.app.fetchBarbarian(this.state.usersData.update_type);
            }}
          ><i class="fa fa-sign-out"></i> Publish Changes</button>
        </div>
      }
    }

    return (
      <Fragment>
        <div className="header-bottom">

          {internetExplorer &&
            <div className="ie-message">
              <span>We noticed you are using Internet Explorer.</span>
              To have the best portal experience, please switch to the <a target="_blank" href="https://www.google.com/chrome/">Chrome web browser</a>.
            </div>
          }

          {(this.state.usersData.online_expires && this.state.usersData.reseller === '') &&
            <div className="renew-message">
              {diff &&
                <Fragment>
                  <span className="renew-icon message-icon"></span>
                  Please renew your account within {diff} days!
                  <Link to={`/dashboard/account/renew`}><span className="p-l-10">Renew</span></Link>
                </Fragment>
              }
            </div>
          }

          {this.state.usersData.archive === '1' &&
            <div className="renew-message">
              <span className="renew-icon message-icon"></span> Your account has been suspended, please process payment to unlock you account.
            </div>
          }

          {submitChangesButton()}

        </div>

        <div className={`content-body ${mode} ${section}`}>
          <div className="left-navigation">
            <select
              value={section}
              onChange={(e) => {
                this.setState({
                  redirect: true,
                  redirectTo: `/dashboard/support/${e.target.value}`,
                });
              }}
            >
              {navData.map((data) => {
                if (!data) {
                  return false;
                }

                return (
                  <option
                    key={data.route}
                    value={data.route}
                  >
                    {this.state.contentData[data.labelKey] || data.defaultLabel}
                  </option>
                )
              })}
            </select>

            <ul>
              {navData.map((data) => {
                if (!data) {
                  return false;
                }

                let activeClassName = 'active';
                if (section !== data.route) {
                  activeClassName = '';
                }

                if (internetExplorer === true) {
                  return (
                    <li
                      key={data.route}
                      className={activeClassName}
                      id={data.id}
                    >
                      <NavLink
                        to={`/dashboard/support/${data.route}`}
                        activeClassName='active'
                        id={data.id}
                      >{this.state.contentData[data.labelKey] || data.defaultLabel}</NavLink>
                    </li>
                  )
                }

                return (
                  <li
                    key={data.route}
                    className={activeClassName}
                  >
                    <NavLink
                      to={`/dashboard/support/${data.route}`}
                      activeClassName='active'
                      id={data.id}
                    >{this.state.contentData[data.labelKey] || data.defaultLabel}</NavLink>
                  </li>
                )
              })}
            </ul>
          </div>

          <div className={`content-section ${mode} ${section}`}>
            {section === 'serviceRequest' &&
              <Fragment>
                <h1>Submit A Service Request</h1>
                <form action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST" className="formTS">

                  <input type="hidden" name="orgid" value="00Dj0000001rkAe" />
                  <input type="hidden" name="retURL" value={thankYouUrl} />
                  <input type="hidden" name="subject" value={this.props.selectedProperty} />

                  <p className="mandatory">* Required Fields</p>

                  <div className="mandatory-subtitle">
                    <span>Please enter the following information, being as specific as possible on your request.   One of our team members will respond before the end of the day.</span>
                  </div>


                  <label for="subject"></label><input id="subject" maxlength="80" name="subject" size="20" type="hidden" /><br></br>

                  <div className="edit-form"><label className="support-form__label" for="name"><span>* </span>Name</label><input required autocomplete="off" id="name" maxlength="80" name="name" size="20" type="text" /><br></br></div>

                  <div className="edit-form"><label className="support-form__label" for="email"><span>* </span>Email</label><input required autocomplete="off" id="email" maxlength="80" name="email" size="20" type="text" /><br></br></div>

                  <div className="edit-form"><label className="support-form__label" for="phone"><span>* </span>Phone</label><input required autocomplete="off" id="phone" maxlength="40" name="phone" size="20" type="text" /><br></br></div>

                  <div className="edit-form"><label className="support-form__label" for="description"><span>* </span>Request</label><textarea required autocomplete="off" name="description"></textarea><br></br></div>

                  <button className="btn" type="submit" name="submit">Submit</button>

                </form>
              </Fragment>
            }

            {section === 'PCtoSupport' &&
              <div>
                {this.renderInstructions()}
                {this.renderSupportList()}
              </div>
            }
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Support;
