import React, { Component, Fragment } from 'react';
import DatePicker from "react-datepicker";

export const formatDateTime = (date) => {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    return date;
  }

  return date
    .toLocaleTimeString()
    .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
    .replace(' ', '')
    .toLowerCase();
}

class Time extends Component {
  state = {
    value: this.props.value,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }

    if (prevProps.clearForm !== this.props.clearForm) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  renderField(field, value) {
    return (
      <DatePicker
        name={''}
        selected={value}
        showTimeSelect={true}
        showTimeSelectOnly={true}
        timeIntervals={15}
        onChange={(date) => {
          console.log(date);
          let nextDate = formatDateTime(date);
          console.log(nextDate);

          this.setState({
            value: nextDate,
          }, () => {
            if (this.props.inline) {
              let formField = document.querySelector(`#${this.props.formId} [name="${field.key}"]`);
              formField.value = formatDateTime(date);
            } else {
              this.props.form.onChange();
            }
          });
        }}
        dateFormat="hh:mm a"
        timeFormat="hh:mm a"
        readOnly={false}
      />
    )
  }

  render() {
    let { label, field } = this.props;

    let value = new Date();
    if (this.state.value && typeof this.state.value === 'object') {
      value = this.state.value;
    }

    if (this.state.value && typeof this.state.value === 'string') {
      let s = this.state.value.replace('am', '').replace('pm', '').split(':');
      let hrs = s[0]; 
      let isPM = this.state.value.toLowerCase().indexOf("pm");

      if (isPM >= 0) {
        try {
          // There's probably a better way.
          hrs = parseInt(hrs) + 12;
          if (hrs === 24) {
            hrs = 12
          }
        } catch (err) {
          console.error(`Failed to parse int out of ${hrs}`);
          console.error(err);
        }
      } else if (hrs === "12") {
        hrs = 0;
      }

      value.setHours(hrs);
      value.setMinutes(s[1]);
    }

    if (!this.state.value) {
      value = '';
    }

    if (this.props.inline) {
      return this.renderField(field, value);
    }

    return (
      <Fragment>
        <div className="text-label-info-left">
          <label>{label}</label>
          <div className="text-input-group">
            <input type="hidden" name={field.key} value={formatDateTime(value)} />
            {this.renderField(field, value)}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Time;