import React, { Component, Fragment } from 'react';

class NumberField extends Component {
  state = {
    value: this.props.value,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }

    if (prevProps.clearForm !== this.props.clearForm) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  renderField(type, field, readOnly) {
    return (
      <input
        type={type}
        name={field.key}
        id={`field-${field.key}`}
        // readOnly={readOnly}

        value={this.state.value || field.value || ''}
        onChange={((e) => {
          let val = e.target.value;
          val = parseInt(val);

          if (field.type === 'number') {
            if (val < 0) {
              console.log('Reset to Zero');
              val = 0;
            }
          }
          if (e.target.value === '') {
            console.log('Reset to Empty String');
            val = '';
          }
          
          if ((!isNaN(val) && val < 0) || isNaN(val)) {
            console.log('Reset to Empty String');
            val = '';
          } else {
            console.log('Change to string value');
            val = val.toString();
            // console.log(this.state);
            // let newState = Object.assign({}, this.state);
            // newState.changes.position__.new = char;
            // this.setState(newState);
          }
          
          this.setState({
            value: val,
          });
          console.log(val);
          console.log(this.state.value);
          
          // let char = parseInt(this.state.number);
          // if ((!isNaN(char) && char < 0) || isNaN(char)) {
          //   char = '';
          // } else {
          //   char = char.toString();
          //   console.log(char);
            // console.log(this.state);
          //   let newState = Object.assign({}, this.state);
          //   newState.changes.position__.new = char;
          //   this.setState(newState);
          // }

          if (this.props.inline) {
            let formField = document.querySelector(`#${this.props.formId} [name="${field.key}"]`);
            formField.value = val;
          } else {
            this.props.form.onChange();

            if (field.required && (!val || val === '')) {
              e.target.classList.add('invalid');
              e.target.classList.remove('valid');
            }

            if (field.required && (val || val !== '')) {
              e.target.classList.remove('invalid');
              e.target.classList.add('valid');
            }
          }
        })}
      />
    )
  }

  render() {
    let { label, field, hidden, debug } = this.props;
    // if (hidden) {
    //   if (!label) {
    //     label = field.key;
    //   }
    // }

    let type = 'text';
    // if (field.type === 'number') {
    //   type = 'text';
    // }

    let readOnly = false;
    if (hidden) {
      type = 'hidden';
      if (debug) {
        hidden = false;
        type = 'text';
      }

      readOnly = true;
    }

    if (this.props.inline) {
      return this.renderField(type, field, readOnly);
    }

    return (
      <Fragment>
        {!hidden &&
          <label>{label}</label>
        }
        {this.renderField(type, field, readOnly)}
      </Fragment>
    )
  }
}

export default NumberField;