import React, { Component, Fragment } from 'react';

class TextArea extends Component {
  state = {
    value: this.props.value,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }

    if (prevProps.clearForm !== this.props.clearForm) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  render() {
    let { label, field } = this.props;

    return (
      <Fragment>
        <label>{label}</label>
        <textarea
          name={field.key}

          // HACK: defaultValue not changing?
          value={this.state.value}
          onChange={((e) => {
            this.setState({
              value: e.target.value,
            });
            this.props.form.onChange();
          })}
        />
      </Fragment>
    )
  }
}

export default TextArea;