import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import kali from 'kali';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import {
  getSectionAjaxRoute,
} from '../data/navData.js';

import {
  getSectionCols,
  getSectionFields,
} from '../data/fieldData.js';

import {
  getFormFieldChanges,
} from '../form/Form.js';

import DateField, { formatDateShort } from '../form/field/Date.js';
import Select from '../form/field/Select.js';
import Text from '../form/field/Text.js';
import Time, { formatDateTime } from '../form/field/Time.js';

const API_PREFIX = process.env.REACT_APP_API_PREFIX;

export const deleteRecord = (portal, parent, section, id, ctx = false) => {
  let index;
  let { sectionData } = parent.state;
  sectionData.forEach((data, i) => {
    if (data.ID === id) {
      index = i;
    }
  });

  let formData = new FormData();
  formData.append("ID", id);
  formData.append("prop_num", sessionStorage['selected_property_number']);

  let confirmDelete = window.confirm("Are you sure you want to delete?");
  if (confirmDelete) {
    let ajaxRoute = getSectionAjaxRoute(section);
    if (section === 'slideshow' || section === 'slideshow2') {
      ajaxRoute = 'updateImages/upload/slideShow';
      formData.append("file_name", sectionData[index].file_name);
    }

    let { url, headers } = parent.props.app.signBroncoURL(`https:${API_PREFIX}/legacy/${ajaxRoute}/?delete=1`);
    new kali({
      headers,
      form: formData,
    }).post(url, {
      success: (_kali, res, data) => {
        portal.setState({
          loading: true,
        });

        if (section === 'companies') {
          if (ctx && typeof ctx.companies !== 'undefined') {
            ctx.companies = data.data;
          }
        }

        sectionData.splice(index, 1);
        parent.setState({
          sectionData,
          editIndex: -1,
          modal: false,
          inline: false,
        }, (e) => {
          // HACK: make it look like it did something
          window.setTimeout(() => {
            portal.setState({
              loading: false,
            });
          }, 500);
        });
        console.log('deleted');
      },

      failure: (_kali, err) => {
        toast.error(err.statusText, {
          position: toast.POSITION.TOP_RIGHT
        });
      },

      serverError: (_kali, res, data) => {
        if (data.error) {
          toast.error(data.error, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      },

      notAuthorized: (_kali, res, data) => {
        if (data.error) {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }

        parent.props.app.logout();
      }
    });
  }
}

class List extends Component {
  state = {
    columnFilterHidden: true,
    columnsHidden: {},
    contentData: this.props.contentData,
    editIndex: this.props.editIndex,
    inline: this.props.inline,
    mode: this.props.mode,
    section: this.props.section,
    sectionData: this.props.sectionData,
    selectData: this.props.selectData,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    let keys = [
      'contentData',
      'editIndex',
      'inline',
      'mode',
      'section',
      'sectionData',
      'selectData',
    ];

    let propsDiffer = false;
    for (let key of keys) {
      if (prevProps[key] !== this.props[key]) {
        propsDiffer = true;
        break;
      }
    }

    if (propsDiffer) {
      this.setState({
        contentData: this.props.contentData,
        editIndex: this.props.editIndex,
        inline: this.props.inline,
        mode: this.props.mode,
        section: this.props.section,
        sectionData: this.props.sectionData,
        selectData: this.props.selectData,
      });
    }
  }

  render() {

    let { parent } = this.props;
    let {
      contentData,
      editIndex,
      inline,
      mode,
      section,
      sectionData,
    } = this.state;

    if (!sectionData) {
      return;
    }

    let columns = [];
    try {
      let sectionFields = getSectionFields(section, contentData);

      columns = getSectionCols(section, contentData || {})
        .map((data) => {
          let header = (contentData[data.labelKey] || data.defaultLabel);
          if (data.required) {
            header = '*' + header;
          }

          return {
            Header: header,
            accessor: data.key,
            filterMethod: (filter, row) => {
              let val = row[filter.id].toString().toLowerCase();
              let searchVal = filter.value.toLowerCase();

              if (~val.indexOf(searchVal)) {
                return true;
              }
              return false;
            },
            Filter: ({ filter, onChange }) => {
              return (
                <input type="text" placeholder="Filter..."
                  onChange={event => onChange(event.target.value)}
                  value={(filter) ? filter.value : ''}
                />
              )
            },
            Cell: (rowInfo) => {
              let field;
              for (let sectionField of sectionFields) {
                if (data.key === sectionField.key) {
                  field = sectionField;
                }
              }
              if (!field) {
                // TODO: ???
              }

              let v = rowInfo.row[data.key] || '';
              if (!inline || editIndex !== rowInfo.index) {
                if (field.type === 'date') {
                  v = formatDateShort(v);
                }

                if (field.type === 'time') {
                  v = formatDateTime(v).toUpperCase();
                }

                return v;
              }

              if (field.type === 'text' || field.type === 'number') {
                return (
                  <Text
                    key={field.key}
                    field={field}
                    value={v}
                    inline={true}
                    formId={`form-${mode}-${section}`}
                  />
                )
              }

              if (field.type === 'select') {
                let label = contentData[field.labelKey] || field.defaultLabel || field.key;
                if (typeof label !== 'string') {
                  debugger;
                }

                return (
                  <Select
                    key={field.key}
                    label={label}
                    field={field}
                    value={v}
                    inline={true}
                    formId={`form-${mode}-${section}`}
                    data={this.props.selectData}
                  />
                )
              }

              if (field.type === 'date') {
                return (
                  <DateField
                    key={field.key}
                    field={field}
                    value={v}
                    inline={true}
                    formId={`form-${mode}-${section}`}
                  />
                )
              }

              if (field.type === 'time') {
                return (
                  <Time
                    key={field.key}
                    field={field}
                    value={v}
                    inline={true}
                    formId={`form-${mode}-${section}`}
                  />
                )
              }

              return v || '';
            },
          }
        });
    } catch (err) {
      console.error(err);
    }

    if (columns.length !== 0) {
      columns.unshift({
        Header: '',
        Cell: (rowInfo) => {
          return (
            <Fragment>
              <a
                href
                className="buttonwidth"
                style={{ color: "#00aeef" }}
              >
                <i className="fa fa-window-restore"
                  onClick={() => {
                    // TODO: detect inline and move changes to modal

                    parent.setState({
                      inline: false,
                      modal: true,
                      editIndex: rowInfo.index,
                      pendingChanges: false,
                    })
                  }}
                ></i>
              </a>

              <a
                href
                className="buttonwidth"
                style={{ color: "#00aeef" }}
              >
                {!inline &&
                  <i className="fa fa-pencil"
                    id={`inline-edit-button-${rowInfo.index}`}
                    onClick={() => {
                      parent.setState({
                        inline: true,
                        modal: false,
                        editIndex: rowInfo.index,
                        pendingChanges: false,
                      })
                    }}
                  ></i>
                }

                {(inline && editIndex !== rowInfo.index) &&
                  <i className="fa fa-pencil"
                    id={`inline-edit-button-${rowInfo.index}`}
                    onClick={() => {
                      // WARN: is this still needed???
                      // WARN: no way it works since being
                      // WARN: brought out of Content.js
                      this.saveButtonCB = () => {
                        parent.setState({
                          modal: false,
                          inline: true,
                          editIndex: rowInfo.index,
                          pendingChanges: false,
                        });
                      }

                      let saveButton = document.getElementById(`inline-save-button-${editIndex}`);
                      saveButton.click();
                    }}
                  ></i>
                }

                {(inline && editIndex === rowInfo.index) &&
                  <i className="fa fa-floppy-o"
                    id={`inline-save-button-${rowInfo.index}`}
                    onClick={() => {
                      let { contentData, sectionData } = this.state;
                      let orgData = sectionData[editIndex];
                      let sectionFields = getSectionFields(section, contentData);

                      let pendingChanges = getFormFieldChanges(mode, section, sectionFields, orgData);
                      if (Object.keys(pendingChanges).length !== 0) {
                        let saveButton = document.querySelector(`#save-button`);
                        saveButton.click();
                      } else {
                        parent.setState({
                          inline: false,
                          modal: false,
                          editIndex: -1,
                        })
                      }
                    }}
                  ></i>
                }
              </a>
            </Fragment>
          )
        },
        accessor: 'edit_col',
        width: 130,
        sortable: false,
        filterable: false,
      });

      columns.push({
        Header: '',
        Cell: (rowInfo) => {
          return (
            <center>
              <a
                href
                className="buttonwidth"
                style={{ color: "#00aeef" }}
              >
                <i className="fa fa-trash"
                  onClick={() => {
                    deleteRecord(this.props.parent.props.portal, this, section, rowInfo.original.ID);
                  }}
                ></i>
              </a >
            </center>
          )
        },
        width: 60,
        sortable: false,
        filterable: false,
      });
    }

    return (
      <Fragment>
        <div className="newlisting-button">
          <button className="btn btn-secondary"
            onClick={() => {
              parent.setState({
                editIndex: -1,
                inline: false,
                modal: true,
                pendingChanges: true,
                clearForm: false,
              });
            }}
          ><i className="fa fa-plus"></i> New Listing</button>
        </div>

        <div id="tableSearch">
          {/* <span className="fa fa-search"></span>
          <input type="search" id="site-search" name="q"
            aria-label="Search through site content" placeholder="Search..." autoComplete="off"

            value={this.state.searchDataFilter || ''}
            onChange={(e) => {
              let searchVal = e.target.value.toLowerCase();
              if (searchVal === '') {
                this.setState({
                  searchDataFilter: searchVal,
                  sectionDataFiltered: false,
                  filteredSectionData: [],
                });

                return;
              }

              let visibleColumnKeys = [];
              columns.forEach((col) => {
                if (col.Header && this.state.columnsHidden[col.Header] !== true) {
                  visibleColumnKeys.push(col.accessor);
                }
              });

              let filteredSectionData = [];
              sectionData.forEach((data) => {
                for (var i in visibleColumnKeys) {
                  let key = visibleColumnKeys[i];
                  let val = data[key].toString().toLowerCase();

                  // console.log(key, val, searchVal);
                  if (~val.indexOf(searchVal)) {
                    return filteredSectionData.push(data);
                  }
                }
              });

              this.setState({
                searchDataFilter: searchVal,
                sectionDataFiltered: true,
                filteredSectionData,
              })
            }}
          /> */}

          <div className="column-filter"
            onMouseLeave={() => {
              this.setState({
                columnFilterHidden: true
              });
            }}>
            <button className={`modal-popup-link ${this.state.columnFilterHidden ? '' : 'active'}`}
              onClick={() => {
                this.setState({
                  columnFilterHidden: !this.state.columnFilterHidden,
                });
              }}
            ><i class="far fa-eye"></i>&nbsp;Filter</button>

            {!this.state.columnFilterHidden &&
              <div id="showColumns" className="modal-popup-box">
                <ul>
                  {columns.map((col) => {
                    if (col.Header) {
                      let checked = this.state.columnsHidden[col.Header] || false;

                      return (
                        <li
                          key={col.Header}
                        >
                          <input type="checkbox" checked={!checked}
                            onChange={(e) => {
                              let { columnsHidden } = this.state;
                              columnsHidden[col.Header] = !columnsHidden[col.Header];

                              this.setState({
                                columnsHidden,
                              });
                            }}
                          />{col.Header}
                        </li>
                      )
                    }

                    return false;
                  })}
                </ul>
              </div>
            }
          </div>
        </div>

        <ReactTable
          data={(() => {
            if (this.state.sectionDataFiltered) {
              return this.state.filteredSectionData;
            }
            return sectionData;
          })()}
          columns={columns.filter((col) => {
            if (this.state.columnsHidden[col.Header] !== true) {
              return col;
            }
            return false;
          })}

          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                // console.log('A Td Element was clicked!');
                // console.log('it produced this event:', e);
                // console.log('It was in this column:', column);
                // console.log('It was in this row:', rowInfo);
                // console.log('It was in this table instance:', instance);

                if (e.target.tagName !== 'I') {
                  if (!inline && !this.state.modal) {
                    parent.setState({
                      inline: true,
                      modal: false,
                      editIndex: rowInfo.index,
                      pendingChanges: false,
                    });
                  }
                }

                // IMPORTANT! React-Table uses onClick internally to trigger
                // events like expanding SubComponents and pivots.
                // By default a custom 'onClick' handler will override this functionality.
                // If you want to fire the original onClick handler, call the
                // 'handleOriginal' function.
                if (handleOriginal) {
                  handleOriginal();
                }
              }
            }
          }}

          minRows={0}
          pageSizeOptions={[10, 15, 30, 50]}
          defaultPageSize={10}
          filterable
          resizable={false}
          defaultSorted={[
            {
              id: (() => {
                // skip 0 for action items(edit, inlineEdit)
                try {
                  return columns[1].accessor;
                } catch (err) {
                  debugger;
                }
              })(),
              desc: false,
            },
          ]}
        />

        <div class='total-records'> Total Records: {this.state.sectionData.length + '\n'}</div>
      </Fragment>
    )
  }
}

export default List;